import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';


@Injectable()
export class ApplicationInsightsService {
   appInsights: ApplicationInsights;

   constructor() {
      this.appInsights = new ApplicationInsights({
         config: {
            instrumentationKey: environment.appInsights.instrumentationKey,
            enableAutoRouteTracking: true // option to log all route changes
         }
      });
      this.appInsights.loadAppInsights();
      this.appInsights.trackPageView({});
   }

   logPageView(name?: string, url?: string) { // option to call manually
      this.appInsights.trackPageView({
         name: name,
         uri: url
      });
   }

   logEvent(name: string, properties?: { [key: string]: any }) {
      this.appInsights.trackEvent({ name: name }, properties);

      if (!environment.production) {
         console.info(name);
         console.info(properties);
      }
   }

   logMetric(name: string, average: number, properties?: { [key: string]: any }) {
      this.appInsights.trackMetric({ name: name, average: average }, properties);

      if (!environment.production) {
         console.info(`${name}:${average}`);
         console.info(properties);
      }
   }

   logException(exception: Error, severityLevel?: number) {
      this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });

      if (!environment.production) {
         console.error(exception);
      }
   }

   logTrace(message: string, properties?: { [key: string]: any }) {
      this.appInsights.trackTrace({ message: message }, properties);

      console.info(name);
      console.info(properties);
   }

   private AddGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
      if (!properties) {
         properties = {};
      }

      //add your custom properties such as app version

      return properties;
   }
}
