import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment'

import { ContentService } from "../../common/services/content.service";
import { RegistrationService } from '../../common/services/registration.service';

import { ISiteContent } from '../../common/models/site-content-model';
import { IContent } from '../../common/models/registration-content.model';
import { ProductCodes } from '../../common/models/product-codes';

declare global {
    interface Window { dataLayer: any; }
}

@Component({
    selector: 'app-sgc-confirmation',
    templateUrl: './sgc-confirmation.component.html',
    styleUrls: ['./sgc-confirmation.component.scss']
})
export class SgcConfirmationComponent implements OnInit {
    secondsToWait = 10;
    loading = true;
    content: IContent;
    siteContent!: ISiteContent | undefined;

    constructor(private router: Router, private location: Location, private contentService: ContentService,
        private registrationService: RegistrationService) {
        this.content = this.contentService.contentsByProduct(ProductCodes.selfGuidedCertification).confirmation;
        this.siteContent = this.contentService.siteContentByProduct(ProductCodes.selfGuidedCertification, "Confirmation");
    }

    ngOnInit(): void {
        this.registrationService.loadRegistrationData();
        if (!this.registrationService.model.savedQuote) {
            this.router.navigate(['account/details']);
        }

        if (this.registrationService.model.productCode === ProductCodes.elevateSubscription) {
            this.router.navigate(['/account/confirmation']);
        }
        else this.location.replaceState('/account/confirmation');

        this.loading = !this.registrationService.accountSetupDone;
        if (this.loading) {
            setTimeout(() => {
                this.loading = false;
                this.registrationService.accountSetupDone = true;
                window.dataLayer.push({ 'event': 'self-guided certification trigger' });
            }, this.secondsToWait * 1000);
        } else {
            window.dataLayer.push({ 'event': 'self-guided certification trigger' });
        }
    }

    continueToLogIn() {
        const email = this.registrationService.model.owner.emailAddress;
        this.registrationService.resetRegistrationData();
        window.location.href = `${environment.sgcLoginUrl}?email=${encodeURIComponent(email)}`;
    }
}
