export class Alert {
    id!: string;    // Note: there is a bug in the component where it will only subscribe to alerts with the id: default-alert.  So don't change this
    type!: AlertType;
    message!: string;
    autoClose!: boolean;
    keepAfterRouteChange?: boolean;
    fade!: boolean;
    alertId!: string;  // Note: instead use this to set the id of the alert

    constructor(init?:Partial<Alert>) {
        Object.assign(this, init);
    }
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}