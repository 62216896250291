import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';  //added after
import { Router } from '@angular/router';
import { Observable } from "rxjs";
import { RegistrationErrorCodes } from '../../common/constants/registration-error-codes';
import { finalize, tap, filter } from 'rxjs/operators';


// services
import { RegistrationService } from '../../common/services/registration.service';
import { ContentService } from '../../common/services/content.service';
import { AlertService } from '../../common/services/alert-service/alert.service';

// models
import { ISubscriptionQuoteRequest } from "../../common/models/subscription-quote-request.model";
import { ISalesQuote, SalesQuote } from "../../common/models/sales-quote-models";
import { IRegistrationSalesQuoteModel } from '../../common/models/self-registration-model.model';

@Component({
    selector: 'app-step4',
    templateUrl: './review.component.html',
    styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {
    content: any;

    step4Form!: FormGroup;
    showPromoCodeLink: boolean = false;
    sourceCode: string = "";
    showApply: boolean = true;
    showSpinner: boolean = true;

    salesQuote: ISalesQuote = new SalesQuote();
    isSourceCodeDiscountApplied: boolean = false;
    isGeneratingQuote = false;
    discount: number = 0;
    errorMessage = '';
    discountErrorMessage: string = "";
    errorCode : string | null = "";
    nextSpinner = false;

    constructor(private router: Router, private formBuilder: FormBuilder, private registrationService: RegistrationService, private route: ActivatedRoute, private contentService: ContentService, private alertService: AlertService) {
        this.content = this.contentService.content.review;
    }

    ngOnInit(): void {
        this.errorMessage = "";
        this.alertService.clear();
        this.step4Form = this.formBuilder.group({
            applyDiscount: '',
        });
        this.route.queryParams.pipe(
            filter(params => params.err)
            ).subscribe(params => {
                
                this.errorCode = params.err;
                if (this.errorCode) {
                    switch (this.errorCode) {
                        case RegistrationErrorCodes.ERROR_INVALID_MODEL_STATE: {
                            // TODO : Handle invalid input parameters here.
                                this.errorMessage = "invalid input parameters.";
                                
                        } break;
                        case RegistrationErrorCodes.ERROR_PAYMENT_AUTH_FAILED: {
                            // TODO : Handle payment authorization failure here.
                                this.errorMessage = "payment authorization failure.";
                        } break;
                        case RegistrationErrorCodes.ERROR_INTERNAL_PROCESSING:
                        {
                            this.errorMessage = "We're unable to process your request at this time. Please try again later. If this problem persists, please contact customer service.";
                        } break;
                        default: {
                            // TODO : Handle unexpected errors here.
                            this.errorMessage = "We're unable to process your request at this time. Please try again later. If this problem persists, please contact customer service.";

                        } break;
                    }
                    this.alertService.error(this.errorMessage);
                }
                if (this.nextSpinner) this.nextSpinner = false;
            });


        if (this.registrationService.accountSetupDone) {
            this.router.navigate(["account/confirmation"]);
        }
        this.registrationService.loadRegistrationData();
        if (!this.registrationService.model.address.countryName) {
            this.router.navigate(['account/details']);
        }
        this.sourceCode = this.registrationService.model.sourceCode;
        this.step4Form.patchValue(this.registrationService.model);

        if (this.registrationService.model.savedQuote && this.registrationService.model.savedQuote.salesQuoteId) {
            if (this.registrationService.model.sourceCode) {
                this.togglePromo();
            }
            this.processQuoteResponse(this.registrationService.model.savedQuote);
            this.showApply = (!this.registrationService.model.savedQuote.isSourceCodeDiscountApplied)
            this.salesQuote = this.registrationService.model.savedQuote;
        } else {
            this.getQuote().subscribe();
        }
    }

    get applyDiscountCtrl() { return this.step4Form.get('applyDiscount') as FormControl; }
    get isFullDiscount(): boolean { return (this.salesQuote?.total === 0); }

    get totalDiscount(): number {
        let total = 0.00;
        for (const li of this.salesQuote.lineItems) {
            total += li.discountAmount
        }
        return total;
    }

    currencyText(value: number) {
        return Intl.NumberFormat(navigator.language, { style: 'currency', currency: this.registrationService.currencyCode }).format(value);
    }

    togglePromo() {
        this.showPromoCodeLink = !this.showPromoCodeLink;
    }

    calculateDiscount() {
        if (this.sourceCode) {
            
            // get sales quote with promo,if promo code was applied, show the remove button
            this.getQuote(this.sourceCode).subscribe(
                (quote: ISalesQuote) => {
                    this.showApply = (!quote.isSourceCodeDiscountApplied)
                });
        }
        return false;
    }

    clearDiscount() {
        if (this.sourceCode) {
            
            // get sales quote with "" promo code, if call worked, show the apply button
            this.getQuote("").subscribe(
                (quote: ISalesQuote) => {
                    if (!quote.isSourceCodeDiscountApplied || quote.sourceCode === '') {
                        this.discountErrorMessage = "";
                        this.sourceCode = "";
                        this.showApply = true;
                    }
                });
        }
        return false;

    }
    getQuote(tempSourceCode?: string): Observable<ISalesQuote> {
        this.discountErrorMessage = "";
        this.isGeneratingQuote = true;
        const observable = (tempSourceCode
            ? this.registrationService.generateSalesQuote(tempSourceCode)
            : this.registrationService.generateSalesQuote());
        return observable.pipe(
            tap((data: ISalesQuote) => this.processQuoteResponse(data)),
            finalize(() => this.isGeneratingQuote = false)
        );
    }
    next(event: Event) {
        this.errorMessage = "";
        
        event.stopPropagation();
        const updates: IRegistrationSalesQuoteModel = {
            salesQuoteId: (this.salesQuote?.salesQuoteId ? this.salesQuote.salesQuoteId : ''),
            savedQuote: (this.salesQuote ? this.salesQuote : null),
            sourceCode: (this.sourceCode ? this.sourceCode : ''),
        }
        this.registrationService.saveRegistrationData(updates);

        this.nextSpinner = true;
        this.registrationService.register().subscribe({ error: (err) => this.nextSpinner = false });
        return false;
    }

    private processQuoteResponse(quote: ISalesQuote) {
        if (quote.isSourceCodeDiscountApplied) {
            this.discount = quote.lineItems[0].discountAmount;
            
            this.isSourceCodeDiscountApplied = true;
        } else {
            if (quote.sourceCode !== "")
                this.discountErrorMessage = "Invalid promo code.";
            
            this.isSourceCodeDiscountApplied = false;
        }

        if (quote.isEstimated) {
            this.alertService.info("Our system is experiencing a disruption and you may not see accurate discounts or tax totals in your check out. You can place your order now and we assure you that we will process the correct amount before billing you when our system is fully operational.");
        }
        
        this.salesQuote = quote;
        this.showSpinner = false;
    }
}
