<div class="container">
    <div class="container py-0">
        <div class="row">
            <div class="col-12">
                <header class="local_header" id="localHeader">
                    <h1>{{content.header}}</h1>
                </header>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col pr-0 pr-md-2"><progress-bar [activeStep]="6"></progress-bar></div><!--1-->
        </div>
        <div class="content-container">
            <div class="row pt-3">
                <div class="col"><div id="contentConfirmation" [innerHTML]="(siteContent ? siteContent.mainContent : '') | safeHtml"></div></div><!--2-->
            </div>
            <div class="row">
                <div class="col">
                    <div class="text-center text-md-right actionbutton">
                        <input type="button" id="submitButton" (click)="continueToLogIn($event)" class="btn-tpl-lg btn-orange-primary" value="Continue to Log In" /><!--3-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
