<div class="container">
    <div class="container py-0">
        <div class="row">
            <div class="col-12">
                <header class="local_header" id="localHeader">
                    <h1>{{content.header}}</h1>
                </header>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md pr-0 pr-md-2"><progress-bar [activeStep]="2"></progress-bar></div>
        </div>
        <div class="content-container">
            <div class="row pt-3">
                <div class="col-md section-subheader-redesign pb-3">{{content.subHeader}}</div>
                <div class="col-md d-none d-lg-block"></div>
            </div>
            <form #securityForm="ngForm" (ngSubmit)="next($event)" [formGroup]="step2Form">
                <div class="row pb-3">
                    <div class="col-md">
                        <label class="input-section-label" for="password" [class.is-invalid-label]="(!passwordCtrl.errors?.required && passwordCtrl.errors) || (passwordCtrl.errors?.required && securityForm.submitted)">Password</label>
                        <input type="password" id="password" formControlName="password" placeholder="Enter your password" class="password-textbox form-input" [class.password-fail-image]="passwordCtrl.errors?.minlength || passwordCtrl.errors?.email_password_equal || passwordCtrl.errors?.password_not_complex" style="margin-right: 20%" maxlength="50" autocomplete="off" data-toggle="tooltip" data-html="true" title="<span class='floating-tooltip'>At least 8 characters: must contain at least 1 digit, 1 uppercase letter, 1 lowercase letter, and 1 special character. Cannot be same as email address.</span>" />
                        <div *ngIf="securityForm.submitted">
                            <div *ngIf="passwordCtrl.errors?.required && securityForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                            <div *ngIf="passwordCtrl.errors?.password_mismatch"><label class="is-invalid-message">Passwords must match.</label></div>
                        </div>
                    </div>
                    <div class="col-md d-none d-lg-block"></div>
                </div>
                <div class="row pb-3">
                    <div class="col-md">
                        <label class="input-section-label" for="confirmPassword" [class.is-invalid-label]="(!confirmPasswordCtrl.errors?.required && confirmPasswordCtrl.errors) || (confirmPasswordCtrl.errors?.required && securityForm.submitted)">Re-enter password</label>
                        <input type="password" id="confirmPassword" formControlName="confirmPassword" placeholder="Re-enter your password" class="password-textbox form-input" [class.password-fail-image]="confirmPasswordCtrl.errors?.minlength || passwordCtrl.errors?.email_password_equal ||  confirmPasswordCtrl.errors?.password_not_complex || (confirmPasswordCtrl.errors?.password_mismatch && confirmPasswordCtrl.dirty)" style="margin-right: 20%" maxlength="50" autocomplete="off" />
                        <div *ngIf="confirmPasswordCtrl.errors?.required && securityForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                        <div *ngIf="confirmPasswordCtrl.errors?.password_mismatch && confirmPasswordCtrl.dirty"><label class="is-invalid-message">Password must match.</label></div>
                    </div>
                    <div class="col-md d-none d-lg-block"></div>
                </div>
                <div class="row pb-5">
                    <div class="col-md">
                        <label class="input-section-label" for="phoneNumber" [class.is-invalid-label]="phoneNumberCtrl.errors?.required && securityForm.submitted">Mobile number</label>
                        <span class="d-block" data-toggle="tooltip" data-html="true" title="<span class='floating-tooltip'>If you need to reset your password, a reset code will be sent to this mobile number.</span>">
                            <ngx-intl-tel-input [preferredCountries]="preferredCountries"
                                                [enableAutoCountrySelect]="true"
                                                [enablePlaceholder]="true"
                                                [searchCountryFlag]="true"
                                                [searchCountryField]="searchCountryFields"
                                                [selectFirstCountry]="false"
                                                [selectedCountryISO]="selectedCountryIso"
                                                [phoneValidation]="true"
                                                [separateDialCode]="true"
                                                [numberFormat]="numberFormat"
                                                placeholder="Enter your mobile number"
                                                name="phoneNumber"
                                                formControlName="phoneNumber">
                            </ngx-intl-tel-input>
                        </span>
                        <div *ngIf="securityForm.submitted">
                            <div *ngIf="phoneNumberCtrl.errors?.required"><label class="is-invalid-message">This field is required.</label></div>
                            <div *ngIf="phoneNumberCtrl.errors && !phoneNumberCtrl.errors?.required"><label class="is-invalid-message">Mobile phone number is not valid. Please ensure formatting is correct.</label></div>
                        </div>

                    </div>
                    <div class="col-md d-none d-lg-block"></div>
                </div>
                <div class="row text-center text-md-right">
                    <div class="col-md"><input type="submit" id="submit" class="btn-tpl-lg btn-orange-primary" value="NEXT" /></div>
                </div>
            </form>
        </div>
    </div>

</div>
