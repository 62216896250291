import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { forkJoin } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PostalCodeValidator } from '../../common/validators/postal-code-validator';
import { VATValidator } from '../../common/validators/vat-validator';

// services
import { AlertService } from '../../common/services/alert-service/alert.service';
import { ContentService } from '../../common/services/content.service';
import { RegistrationService } from '../../common/services/registration.service';

// models
import { RegistrationErrorCodes } from '../../common/constants/registration-error-codes';
import { AddressModel, IAddressModel } from '../../common/models/address-verification-models';
import { ICategoryGroup, ISubCategoryGroup } from '../../common/models/category.model';
import { ICountryRegion } from '../../common/models/country-region.model';
import { ICountry } from '../../common/models/country.model';
import { IEmployeeEstimateOption } from '../../common/models/employee-estimate-option.model';
import { ProductCodes } from '../../common/models/product-codes';
import { ISalesQuote } from '../../common/models/sales-quote-models';
import { IRegistrationSalesQuoteModel } from '../../common/models/self-registration-model.model';
import { ITimeZone } from '../../common/models/time-zone.model';

@Component({
    selector: 'app-step3',
    templateUrl: './organization.component.html',
    styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
    content: any;

    step3Form!: FormGroup;
    titles!: string[];
    numberOfEmployees!: IEmployeeEstimateOption[];
    categories!: ICategoryGroup;
    subCategories!: ISubCategoryGroup[];
    regions!: ICountryRegion[];
    timeZones: ITimeZone[] = [];

    preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
    searchCountryFields: SearchCountryField[] = [SearchCountryField.Iso2, SearchCountryField.Name];
    selectedCountryIso: CountryISO = CountryISO.UnitedStates;
    numberFormat: PhoneNumberFormat = PhoneNumberFormat.National;
    showAddress3Link: boolean = true;
    errorMessage = '';
    errorCode: string | null = "";
    nextSpinner = false;

    enteredAddress: IAddressModel = new AddressModel();
    suggestedAddress: IAddressModel = new AddressModel();
    isAddressOptionSelected = false;

    enteredCityStateZip: string = '';
    suggestedCityStateZip: string = '';

    selectedSubCategory: ISubCategoryGroup | null = null;
    selectedCountry!: ICountry | undefined;

    constructor(private router: Router, private formBuilder: FormBuilder, private registrationService: RegistrationService, private contentService: ContentService, private alertService: AlertService, private route: ActivatedRoute) {
        this.content = this.contentService.content.organization;

        this.registrationService.timeZones.subscribe(value => {
            this.timeZones = value;
            const timeZone = this.registrationService.model.defaultTimeZone;
            if (timeZone) {
                this.step3Form.get('defaultTimeZone')?.setValue(timeZone);
            }
        });
    }

    ngOnInit(): void {
        this.route.queryParams.pipe(
            filter(params => params.err)
        ).subscribe(params => {

            this.errorCode = params.err;
            if (this.errorCode) {
                switch (this.errorCode) {
                    case RegistrationErrorCodes.ERROR_INVALID_MODEL_STATE: {
                        this.errorMessage = "invalid input parameters.";
                    } break;
                    case RegistrationErrorCodes.ERROR_INTERNAL_PROCESSING: {
                        this.errorMessage = "We're unable to process your request at this time. Please try again later. If this problem persists, please contact customer service.";
                    } break;
                    case RegistrationErrorCodes.ERROR_INVALID_CAPTCHA: {
                        this.errorMessage = "Invalid CAPTCHA response from Google. Please contact customer service.";
                    } break;
                    default: {
                        this.errorMessage = "We're unable to process your request at this time. Please try again later. If this problem persists, please contact customer service.";
                    } break;
                }
                this.alertService.info(this.errorMessage);
            }
            if (this.nextSpinner) this.nextSpinner = false;
        });

        this.step3Form = this.formBuilder.group({
            numberOfEmployees: [null, [Validators.required]],
            category: [null, [Validators.nullValidator]],
            subCategory: [null, [Validators.nullValidator]],
            owner: this.formBuilder.group({
                title: [null, [Validators.required]],
                isConsultant: [null, Validators.required]
            }),
            isTaxExempt: ['', Validators.nullValidator],
            address: this.formBuilder.group({
                addressLine1: ['', Validators.required],
                addressLine2: ['', Validators.nullValidator],
                addressLine3: ['', Validators.nullValidator],
                city: ['', Validators.required],
                stateOrRegion: [null, Validators.required],
                postalCode: [''],
                countryCode: ['', [Validators.required]],
                countryName: ['', [Validators.required]],
            }),
            phoneNumber: ['', Validators.required],
            defaultTimeZone: ['', Validators.required],
            vatRegistrationNumber: ['']
        });

        if (this.registrationService.accountSetupDone) {
            this.router.navigate(["account/confirmation"]);
        }
        this.registrationService.loadRegistrationData();
        if (!this.registrationService.model.address.countryName) {
            this.router.navigate(['account/details']);
        }
        this.step3Form.patchValue(this.registrationService.model, { onlySelf: true, emitEvent: false });
        this.step3Form.markAsPristine();
        this.isAddressOptionSelected = this.registrationService.model.address.validated;

        forkJoin(
            this.registrationService.organizationDropdowns,
            this.registrationService.countries,
            this.registrationService.regions.pipe()
        ).subscribe(
            ([organizationDropdowns, countries, regions]) => {
                this.titles = organizationDropdowns.find(x => x.section === "titles")?.content as string[];
                this.numberOfEmployees = organizationDropdowns.find(x => x.section === "numberOfEmployees")?.content as IEmployeeEstimateOption[];
                this.categories = organizationDropdowns.find(x => x.section === "categories")?.content as ICategoryGroup;
                this.subCategories = organizationDropdowns.find(x => x.section === "subcategories")?.content as ISubCategoryGroup[];
                this.selectedCountry = countries.filter(c => c.countryCode === this.registrationService.model.address.countryCode).shift();
                this.regions = regions.filter(r => r.countryCode === this.selectedCountry?.countryCode);

                this.step3Form.get('owner.title')?.reset(undefined, { emitEvent: false });
                if (this.registrationService.model.owner.title) {
                    this.step3Form.get('owner.title')?.setValue(this.registrationService.model.owner.title, { emitEvent: false })
                }
                this.step3Form.get('numberOfEmployees')?.reset(undefined, { emitEvent: false });
                if (this.registrationService.model.numberOfEmployees) {
                    this.step3Form.get('numberOfEmployees')?.setValue(this.registrationService.model.numberOfEmployees, { emitEvent: false });
                }
                this.step3Form.get('owner.isConsultant')?.reset(undefined, { emitEvent: false });
                if (typeof this.registrationService.model.owner.isConsultant !== "undefined") {
                    const isConsultant = (this.registrationService.model.owner.isConsultant ? "Y" : "N");
                    this.step3Form.get('owner.isConsultant')?.setValue(isConsultant, { emitEvent: false });
                }
                this.step3Form.get('category')?.reset(undefined, { emitEvent: false });
                if (this.registrationService.model.category) {
                    const value = this.registrationService.model.category;
                    this.step3Form.get('category')?.setValue(value, { emitEvent: false });
                    this.handleCategoryValueChanged(value, true);
                }
                this.step3Form.get('address.stateOrRegion')?.reset(undefined, { emitEvent: false });
                if (this.registrationService.model.address.stateOrRegion) {
                    this.step3Form.get('address.stateOrRegion')?.setValue(this.registrationService.model.address.stateOrRegion, { emitEvent: false });
                }

                const address = this.addressGrp.controls;
                if (this.selectedCountry?.stateProvinceOptional) {
                    address.stateOrRegion.clearValidators();
                    address.stateOrRegion.updateValueAndValidity({ emitEvent: false });
                }
                if (!this.selectedCountry?.isPostalCodeOptional) { 
                    address.postalCode.setValidators([Validators.required, PostalCodeValidator.Validate(this.selectedCountry?.countryCode??'')]);
                    address.postalCode.updateValueAndValidity({ emitEvent: false });
                }
                if (this.selectedCountry?.isVATCollected) {
                    this.vatCtrl.setValidators([VATValidator.Validate(this.selectedCountry)]);
                    this.vatCtrl.updateValueAndValidity({ emitEvent: false });
                }
            }
        );

        this.step3Form.get("owner.isConsultant")?.valueChanges.subscribe(newValue => {
            if (newValue === "Y") {
                this.categoryCtrl.clearValidators();
                this.subCategoryCtrl.clearValidators();
            } else if (newValue === "N") {
                this.categoryCtrl.setValidators(Validators.required);
                this.subCategoryCtrl.setValidators([Validators.required]);
            }
            this.categoryCtrl.reset();
            this.categoryCtrl.updateValueAndValidity({ emitEvent: false });
            this.subCategoryCtrl.reset();
            this.subCategoryCtrl.updateValueAndValidity({ emitEvent: false });
        });

        this.step3Form.get('address')?.valueChanges.subscribe((newValue) => {
            this.isAddressOptionSelected = false;
        });

        this.categoryCtrl?.valueChanges.subscribe(newValue => this.handleCategoryValueChanged(newValue));

        $('[data-toggle="tooltip"]').tooltip({
            placement: 'auto',
            html: true,
            trigger: 'hover',
            fallbackPlacement: ["right", "left", "top", "bottom"]
        });

        $('[data-toggle="popover"]').popover({
            template: document.getElementById('popover-template.html')?.innerHTML
        });

        const phoneCountry = this.registrationService.model.address.countryCode.toLowerCase() as CountryISO;
        if (Object.values(CountryISO).includes(phoneCountry)) {
            this.selectedCountryIso = phoneCountry;
        }               
    }

    ngOnDestroy() {
        $('[data-toggle="tooltip"]').tooltip('dispose');
    }

    get numberOfEmployeesCtrl(): FormControl { return this.step3Form.get('numberOfEmployees') as FormControl; }
    get categoryCtrl(): FormControl { return this.step3Form.get('category') as FormControl; }
    get subCategoryCtrl(): FormControl { return this.step3Form.get('subCategory') as FormControl; }
    get titleCtrl(): FormControl { return this.step3Form.get('owner.title') as FormControl; }
    get isConsultantCtrl(): FormControl { return this.step3Form.get('owner.isConsultant') as FormControl; }
    get isTaxExemptCtrl(): FormControl { return this.step3Form.get('isTaxExempt') as FormControl; }
    get addressGrp(): FormGroup { return this.step3Form.controls.address as FormGroup; }
    get addressLine1Ctrl(): FormControl { return this.step3Form.get('address.addressLine1') as FormControl; }
    get addressLine2Ctrl(): FormControl { return this.step3Form.get('address.addressLine2') as FormControl; }
    get addressLine3Ctrl(): FormControl { return this.step3Form.get('address.addressLine3') as FormControl; }
    get cityCtrl(): FormControl { return this.step3Form.get('address.city') as FormControl; }
    get stateOrRegionCtrl(): FormControl { return this.step3Form.get('address.stateOrRegion') as FormControl; }
    get postalCodeCtrl(): FormControl { return this.step3Form.get('address.postalCode') as FormControl; }
    get countryNameCtrl(): FormControl { return this.step3Form.get('address.countryName') as FormControl; }
    get phoneNumberCtrl(): FormControl { return this.step3Form.get('phoneNumber') as FormControl; }
    get defaultTimeZoneCtrl(): FormControl { return this.step3Form.get('defaultTimeZone') as FormControl; }
    get vatCtrl(): FormControl { return this.step3Form.get('vatRegistrationNumber') as FormControl; }

    cityStateZip(address: IAddressModel): string {
        let components = [];

        if (address.city)
            components.push(address.city);
        if (address.stateOrRegion)
            components.push(address.stateOrRegion);
        if (address.postalCode)
            components.push(address.postalCode);

        return components.join(", ");
    }

    showNotValidAddressPopup() {
        $('#notValidAddressPopup').modal('show');
    }

    showEnteredAndSuggestedAddressPopup() {
        $("#addressSelectError").hide();
        $('#enteredAndSuggestedAddressPopup').modal('show');
    }

    showValidationServiceDownPopup() {
        $('#ValidationServiceDownPopup').modal('show');
    }

    toggleAddress3() {

        this.showAddress3Link = !this.showAddress3Link;
    }

    // handler for enteredAndSuggestedAddressPopup AND notValidAddressPopup Close links
    closeAddressValidation() {
        $('#notValidAddressPopup').modal('hide');
        $('#enteredAndSuggestedAddressPopup').modal('hide');
    }
    // handler for enteredAndSuggestedAddressPopup AND notValidAddressPopup Edit link
    editAddress(event: Event) {
        // close modal
        $("#addressSelectError").hide();
        $('#enteredAndSuggestedAddressPopup').modal('hide');

        this.isAddressOptionSelected = false;
    }
    //handler for notValidAddressPopup Keep entered address links
    keepAddress(event: Event) {
        $('#notValidAddressPopup').modal('hide');
        this.isAddressOptionSelected = true;
    }
    // handler for enteredAndSuggestedAddressPopup USE ENTERED ADDRESS button
    selectEnteredAddress() {

        $("#addressSelectError").hide();
        $('#enteredAndSuggestedAddressPopup').modal('hide');
        this.isAddressOptionSelected = true;
        this.generateQuoteAndNavigate();
    }
    // handler for enteredAndSuggestedAddressPopup USE SUGGESTED ADDRESS button
    selectSuggestedAddress() {

        $("#addressSelectError").hide();
        $('#enteredAndSuggestedAddressPopup').modal('hide');
        this.addressGrp.patchValue(this.suggestedAddress);
        this.isAddressOptionSelected = true;
        this.generateQuoteAndNavigate();
    }
    selectEnteredAddressNoValidation() {
        $('#ValidationServiceDownPopup').modal('hide');
        this.generateQuoteAndNavigate();
    }
    validateAddress() {
        this.nextSpinner = true;
        this.registrationService.validateAddress(this.addressGrp.value)
            /*            .pipe(finalize(() => { this.nextSpinner = false; })) because this may run AFTER a call that started up the spinner-which would then hide the spinner */
            .subscribe({
                next: address => {
                    if (address !== null) {

                        this.showEnteredAndSuggestedAddressPopup();
                        this.enteredAddress = this.addressGrp?.value;
                        this.suggestedAddress = address;
                        this.suggestedAddress.countryName = this.addressGrp.value.countryName;
                        this.enteredCityStateZip = this.cityStateZip(this.enteredAddress);
                        this.suggestedCityStateZip = this.cityStateZip(this.suggestedAddress);
                    } else {

                        this.generateQuoteAndNavigate();
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.nextSpinner = false;
                    if (err.status === 0) {

                        this.generateQuoteAndNavigate();
                    }
                    if (err.status === 500) {

                        this.showNotValidAddressPopup();
                    }
                    //FedEx service down for maintenance.
                    if (err.status === 503) {
                        this.showValidationServiceDownPopup();
                    }
                },
                complete: () => { this.nextSpinner = false; }
            });
    }

    private handleCategoryValueChanged(newValue: string | null, initializing: boolean = false) {
        if (newValue !== null) {
            const selected = this.subCategories.filter(sc => sc.categoryId == newValue).shift();
            if (selected) {
                this.selectedSubCategory = selected;
                this.subCategoryCtrl.updateValueAndValidity();

                let value: string | null = null;
                if (initializing) {
                    value = this.registrationService.model.subCategory;
                    if (!selected.values.filter(sc => sc.subCategoryId === value).shift()) {
                        value = null;
                    }
                    this.subCategoryCtrl.setValue(value);
                }
                if (!value) { this.subCategoryCtrl.reset(); }
            }
        }
    }

    next(_: Event) {
        if (!this.step3Form.valid) {
            return;
        }
        if (!this.isAddressOptionSelected) {
            this.validateAddress();
        } else {
            this.generateQuoteAndNavigate();
        }
    }

    async generateQuoteAndNavigate() {
        const limitedAccess = this.registrationService.productCode === ProductCodes.limitedAccess;

        const commands = limitedAccess
            ? ['/account/confirmation/limited']
            : ['/account/review'];

        this.nextSpinner = true;

        try {

            if (!this.registrationService.hasSalesQuote()) {
                const quote: ISalesQuote = await this.registrationService.generateSalesQuote(this.addressGrp.value).toPromise();

                const update: IRegistrationSalesQuoteModel = {
                    savedQuote: quote,
                    salesQuoteId: quote.salesQuoteId,
                    sourceCode: quote.sourceCode,
                };

                this.registrationService.patchModel(update);
            }
        } catch (ex: any) {
            // Just log failure to console - errors will either be reported in review step (elevate/SGC) or ignored (limited access)
            console.debug(ex);
        }

        this.registrationService.patchModel(this.step3Form.value);
        this.registrationService.saveRegistrationData();

        // This following will always redirect to one of three locations:
        // 1. This page, if there was an error registering the account
        // 2. The confirmation page, for a limited access account that was successfully registered
        // 3. The review order order, for Elevate and SGC accounts
        if (limitedAccess) {
            await this.registrationService.register().toPromise();
        } else {
            this.router.navigate(commands);
        }

        this.nextSpinner = false;
    }
}
