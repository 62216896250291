import { Guid } from "guid-typescript";

export interface IUser {
    FirstName: string;
    LastName: string;
    EmailAddress: string;
    PhoneNumber: string;
    GtmId: Guid;
}

export class User implements IUser {
    FirstName: string = "";
    LastName: string = "";
    EmailAddress: string = "";
    PhoneNumber: string = "";
    GtmId: Guid = Guid.createEmpty();
}

export interface IUserNameUpdate {
    FirstName: string;
    LastName: string;
}
