import { Component, OnInit } from '@angular/core';
import { ExternalUrls } from '../../common/constants/external-urls';
import { Router } from '@angular/router';

@Component({
    selector: 'site-header',
    templateUrl: './site-header.component.html',
    styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent implements OnInit {
    name = "";
    deactivateLinks = true;
    deactivateCertification = false;
    contactUs = ExternalUrls.ContactUs;

    constructor(public router: Router) { }

    ngOnInit() {
        
    }

    contactClick() {
        window.open(this.contactUs, '_blank');
    }
}