<footer>
    <div class="container">
        <div class="row">
            <div class="col-md-3 text-md-left text-center mb-3 mb-md-0">
                <a href="{{bCorpImpact}}" target="_blank">
                    <img class="footer-logo" alt="The Myers Briggs Company" src="assets/img/mb-logo-bcorp.svg" />
                </a>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-2 text-center"><a class="footer-link" target="_blank" data-content="Help" href="{{help}}">Help</a></div>
                    <div class="col-md-3 text-center"><a class="footer-link" target="_blank" data-content="Privacy Policy" href="{{privacyPolicy}}">Privacy Policy</a></div>
                    <div class="col-md-3 text-center"><a class="footer-link" target="_blank" data-content="Trademarks and License" href="{{trademarksAndLicense}}">Trademarks and License</a></div>
                    <div class="col-md-2 text-center"><a class="footer-link" target="_blank" data-content="Terms of Use" href="{{termsOfUse}}">Terms of Use</a></div>
                    <div class="col-md-2 text-center"><a class="footer-link" target="_blank" data-content="Cookie Policy" href="{{cookiePolicy}}">Cookie Policy</a></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-md-right text-center mt-3">
                <div class="copyright-section">
                    &copy;{{currentYear}} The Myers-Briggs Company
                </div>
            </div>
        </div>
    </div>
</footer>