<div class="container">
    <div class="container py-0">
        <div class="row">
            <div class="col-12">
                <header class="local_header" id="localHeader">
                    <h1>{{content.header}}</h1>
                </header>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col pr-0 pr-md-2"><progress-bar [activeStep]="6"></progress-bar></div><!--1-->
        </div>
        <div *ngIf="loading">
            <div class="row pt-3">
                <div class="col">
                    <div class="section-subheader-redesign my-4">Creating your account</div>
                    <div class="pb-3">
                        Please wait while we set up your account...
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="d-flex justify-content-center pad">
                        <div class="spinner-border custom-spinner" role="status">
                            <span class="sr-only">Creating your account...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!loading">
            <div class="content-container">
                <div class="row pt-3">
                    <div class="col"><div id="contentConfirmation" [innerHTML]="(siteContent ? siteContent.mainContent : '') | safeHtml"></div></div><!--2-->
                </div>
                <div class="row">
                    <div class="col">
                        <div class="text-center text-md-right actionbutton">
                            <input type="button" id="submitButton" (click)="continueToLogIn()" class="btn-tpl-lg btn-orange-primary" value="Continue to Log In" /><!--3-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
