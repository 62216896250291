import { Component, OnInit } from '@angular/core';
import { ExternalUrls } from '../../common/constants/external-urls';

@Component({
    selector: 'site-footer',
    templateUrl: './site-footer.component.html',
    styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent implements OnInit {
    currentYear = new Date().getFullYear();
    help = ExternalUrls.Help;
    privacyPolicy = ExternalUrls.PrivacyPolicy;
    trademarksAndLicense = ExternalUrls.TrademarksAndLicense;
    termsOfUse = ExternalUrls.TermsOfUse;
    cookiePolicy = ExternalUrls.CookiePolicy;
    bCorpImpact = ExternalUrls.BCorpImpact;

    constructor() { }

    ngOnInit() {

    }
}