import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { NotFoundComponent } from './error/not-found.component';
import { RegistrationComponent } from './registration/registration.component';
import { AccountComponent } from './registration/account/account.component';
import { SecurityComponent } from './registration/security/security.component';
import { OrganizationComponent } from './registration/organization/organization.component';
import { ReviewComponent } from './registration/review/review.component';
import { ConfirmationComponent } from './registration/confirmation/confirmation.component';
import { SgcConfirmationComponent } from './registration/sgc-confirmation/sgc-confirmation.component';

/*
 * This checks to see if the url is appended with a target (ex. https://certificaton.themyersbriggs.com/?target=%2Faccount-management)
 * If so, it removes everything up to and including ?target=%2F, saves it as "segment" and takes the user to that route
 * In the event that there is no route (ex. https://certificaton.themyersbriggs.com/?target=%2F) it takes the user
 * to the defaultRoute (which we can set to whatever we want)
 * In the event that the target is an invalid route (ex. https://certificaton.themyersbriggs.com/?target=%2Fabc)
 * it takes the user to the component listed in the Routes array (ex. { matcher: checkTarget, component: ProgramOverviewComponent })
 *
 * Update: if we are passing a parameter, (ex. u=1) the url wil look like this: /?target=%2Faccount-management%3Fu=1
 * Then we are creating a KVP for { u: 1 } and passing it into the "parameters" parameter of the UrlSegment
 * It gets translated into a matrix parameter, so the resulting url is https://certificaton.themyersbriggs.com/#/account-management;u=1
 * Note: Currently we don't have the functionality to handle more than one query parameter in the /target link
 */
export function checkTarget(url: UrlSegment[]) {
    if (location.href.includes("?target=")) {
        const defaultRoute = "certification";

        let segment = location.href.split("?target=%2F").pop() ?? "";

        // Parse querystring into matrix parameter
        if (segment.indexOf("%3F") > -1) {
            const querystring = segment.split('%3F').pop() ?? "";
            const key: string = querystring.split('=')[0];
            const value = querystring.split('=')[1];
            const parameters = { [key]: value };

            segment = segment.length ? segment : defaultRoute;
            segment = segment.split('%3F')[0] ?? "";
            url = [new UrlSegment(segment, parameters)];
        } else {
            segment = segment.length ? segment : defaultRoute;
            url = [new UrlSegment(segment, {})];
        }

        return { consumed: url };
    }
    return null;
}


const routes: Routes = [
    {
        path: 'error',
        component: ErrorComponent
    },
    {
        matcher: checkTarget,
        component: RegistrationComponent
    },
    {
        path: '',
        component: AccountComponent,
    },
    {
        path: 'account',
        children: [
            {
                path: 'details',
                component: AccountComponent,
            },
            {
                path: 'security',
                component: SecurityComponent,
            },
            {
                path: 'organization',
                component: OrganizationComponent
            },
            {
                path: 'review/:err',
                component: ReviewComponent
            },
            {
                path: 'review',
                component: ReviewComponent
            },
            {
                path: 'confirmation/sgc',
                component: SgcConfirmationComponent
            },
            {
                path: 'confirmation/limited',
                component: ConfirmationComponent
            },
            {
                path: 'confirmation',
                component: ConfirmationComponent
            }]
    },
    {
        path: 'MBTICertification/account',
        children: [
            {
                path: 'details',
                component: AccountComponent,
            },
            {
                path: 'security',
                component: SecurityComponent,
            },
            {
                path: 'organization',
                component: OrganizationComponent
            },
            {
                path: 'review/:err',
                component: ReviewComponent
            },
            {
                path: 'review',
                component: ReviewComponent
            },
            {
                path: 'confirmation/sgc',
                component: SgcConfirmationComponent
            },
            {
                path: 'confirmation',
                component: ConfirmationComponent
            }]
    },

    {
        path: '**',
        component: NotFoundComponent
    }
];


@NgModule({
    // Without the useHash, urls will take user to MVC routes
    imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
