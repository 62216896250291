import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment'

import { ContentService } from "../../common/services/content.service";
import { RegistrationService } from '../../common/services/registration.service';

import { ISiteContent } from '../../common/models/site-content-model';
import { ProductCodes } from '../../common/models/product-codes';


@Component({
    selector: 'app-step6',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

    content: any;
    subscriptionLoginUrl: string;
    siteContent!: ISiteContent | undefined;

    constructor(private router: Router, private contentService: ContentService, private registrationService: RegistrationService) {

        // This is necesary because there is no product code cookie available on the confirmation page
        const productCode = this.router.url == '/account/confirmation/limited'
            ? ProductCodes.limitedAccess
            : ProductCodes.elevateSubscription;
        const email = this.registrationService.model.owner.emailAddress;

        this.content = this.contentService.contentsByProduct(productCode).confirmation;
        this.subscriptionLoginUrl = `/Subscription/Access?product=${productCode}&email=${encodeURIComponent(email)}`;
        this.siteContent = this.contentService.siteContentByProduct(productCode, "Confirmation");
    }

    ngOnInit(): void {
        this.registrationService.loadRegistrationData();
        if (!this.registrationService.model.savedQuote) {
            this.router.navigate(['account/details']);
        }
        if (this.router.url == '/account/confirmation/limited') {
            window.dataLayer.push({ 'event': 'limited access account trigger' });
        }
        if (this.registrationService.model.productCode === ProductCodes.selfGuidedCertification) {
            this.router.navigate(['/account/confirmation/sgc']);
        }
        this.registrationService.accountSetupDone = true;
    }

    continueToLogIn(event: any) {
        this.registrationService.resetRegistrationData();
        window.location.href = this.subscriptionLoginUrl;
    }

}
