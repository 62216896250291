import { IOrganizationOwnerModel, OrganizationOwnerModel } from "../models/organization-owner-model.model";
import { IAddressModel, AddressModel } from "./address-verification-models";
import { IPhoneNumber } from "./phone-number.model";
import { ISalesQuote } from "./sales-quote-models";

export interface ISelfRegistrationModel {
    address: IAddressModel;
    category: string;
    subCategory: string;
    organizationName: string;
    numberOfEmployees: number | undefined;
    owner: IOrganizationOwnerModel;
    salesQuoteId: string;
    sourceCode: string;
    phoneNumber: IPhoneNumber | string;
    isTaxExempt: boolean;
    productCode?: string;
    savedQuote: ISalesQuote | null;
    defaultTimeZone?: string;
    vatRegistrationNumber?: string;
}
export interface IRegistrationSalesQuoteModel {
    salesQuoteId?: string;
    sourceCode?: string;
    savedQuote: ISalesQuote | null;
}
export class SelfRegistrationModel implements ISelfRegistrationModel, IRegistrationSalesQuoteModel {
    address = new AddressModel();
    category = '';
    subCategory = '';
    organizationName = '';
    numberOfEmployees = undefined;
    owner = new OrganizationOwnerModel();
    salesQuoteId = '';
    sourceCode = '';
    phoneNumber: IPhoneNumber | string = '';
    isTaxExempt = false;
    productCode?: string;
    savedQuote: ISalesQuote | null = null;
    defaultTimeZone?: string;
    vatRegistrationNumber?: string;
    captchaResponse?: string;
}
