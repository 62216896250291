import { FormGroup, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export class PasswordValidator {
    static passwordsMustBeEqual(formCtrlOne: string, formCtrlTwo: string): ValidatorFn {
        return (fg: AbstractControl): ValidationErrors | null => {

            const fieldOne = fg.get(formCtrlOne);
            const fieldTwo = fg.get(formCtrlTwo);
            if (fieldOne && fieldTwo) {
                if (fieldOne.value || fieldTwo.value) {
                    if (fieldOne.value !== fieldTwo.value) {
                        // Use set error methods like this to append 'password_mismatch' error with the existing errors.
                        fieldOne.setErrors({ ...fieldOne.errors, ...{ 'password_mismatch': true } });
                        fieldTwo.setErrors({ ...fieldTwo.errors, ...{ 'password_mismatch': true } });
                    } else {
                        let fieldOneError = { ...fieldOne.errors };
                        delete (fieldOneError['password_mismatch']);
                        // If there is no keys in the error object, it means that the contol has no error
                        // In that case set the object as null so that the error marking for the form control works as expected
                        if (Object.keys(fieldOneError).length > 0) {
                            fieldOne.setErrors(fieldOneError);
                        } else {
                            fieldOne.setErrors(null);
                        }


                        let fieldTwoError = { ...fieldTwo.errors };
                        delete (fieldTwoError['password_mismatch']);
                        // If there is no keys in the error object, it means that the contol has no error
                        // In that case set the object as null so that the error marking for the form control works as expected
                        if (Object.keys(fieldTwoError).length > 0) {
                            fieldTwo.setErrors(fieldTwoError);

                        } else {
                            fieldTwo.setErrors(null);

                        }
                    }
                }
            }
            return null;
        }
    }

    static emailAddressMustNotEqualPassword(formCtrlOne: string, formCtrlTwo: string, emailAddress: string | null):
        ValidatorFn {
        return (fg: AbstractControl): ValidationErrors | null => {
            const fieldOne = fg.get(formCtrlOne);
            const fieldTwo = fg.get(formCtrlTwo);
            if (fieldOne) {
                if (fieldOne.value) {
                    if (fieldOne.value.toString().toLowerCase().trim() === emailAddress?.toLowerCase().trim()) {
                        fieldOne.setErrors({ ...fieldOne.errors, ...{ 'email_password_equal': true } });
                    }
                }
            }
            if (fieldTwo) {
                if (fieldTwo.value) {
                    if (fieldTwo.value.toString().toLowerCase().trim() === emailAddress?.toLowerCase().trim()) {
                        fieldTwo.setErrors({ ...fieldTwo.errors, ...{ 'email_password_equal': true } });
                    }
                }
            }
            return null;
        }
    }

    static passwordMustBeComplex(formCtrlOne: string, formCtrlTwo: string): ValidatorFn {
        return (fg: AbstractControl): ValidationErrors | null => {
            const regex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\w\d\s]).{6,128})/;
            const fieldOne = fg.get(formCtrlOne);
            const fieldTwo = fg.get(formCtrlTwo);
            if (fieldOne) {
                if (fieldOne.value) {
                    if (!regex.test(fieldOne.value.toString().trim()))
                        fieldOne.setErrors({ ...fieldOne.errors, ...{ 'password_not_complex': true } });
                }
            }
            if (fieldTwo) {
                if (fieldTwo.value) {
                    if (!regex.test(fieldTwo.value.toString().trim()))
                        fieldTwo.setErrors({ ...fieldTwo.errors, ...{ 'password_not_complex': true } });
                }
            }

            return null;
        }

    }
}