/**
 * Create a directive called mbcKeydownEvent that will emit an event called mbcKeydown when a keydown event is triggered.
 * The directive should be able to accept an input parameter called mbcKeydownEvent that will be the key that will trigger the event.
 *
 * @example - Bind L and space.
 * <input type="text" mbcKeydown [mbcKeydownOptions]="'L, '" (mbcOnKeydown)="onKeydown($event)" />
 * @example - Bind just space.
 * <input type="text" mbcKeydown [mbcKeydownOptions]="' '" (mbcOnKeydown)="onKeydown($event)" />
 *
 * @note
 * To only specify a space as a key, you can include just an empty string like: ' '
 * To only specify a space as a key and another key, you must include a comma before and after the space like: 'L, ,'
 */
import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[mbcKeydown]',
})
export class MbcKeydownDirective {
  // Input that will accept a comma seperated value of keys that will trigger the event.  If no keys are provided just default to Enter and space.
  private _mbcKeydownOptions: string[] = ['Enter', ' '];
  @Input() set mbcKeydownOptions(keys: string) {

    // Only trim after a comma if the key isn't a space.
    let mbcKeydownOptions = keys.split(',');
    this._mbcKeydownOptions = mbcKeydownOptions.map((key) => {
      if (key !== ' ') {
        return key.trim();
      }
      return key;
    });
  }

  @Output() mbcOnKeydown = new EventEmitter();

  @Input() mbcTabindex: number = 0;

  constructor(private el: ElementRef) {}

  @HostBinding('attr.tabindex') get tabindex(): number {
    return this.mbcTabindex;
  }

  @HostListener('keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key && this._mbcKeydownOptions.includes(event.key)) {
      this.mbcOnKeydown.emit(event);
    }
  }
}
