import { Injectable } from '@angular/core';
import { Observable, Observer } from "rxjs"

@Injectable({
   providedIn: 'root'
})
export class ExternalScriptsService {

   private scripts: ScriptModel[] = [];

   public load(script: ScriptModel): Observable<ScriptModel> {
      return new Observable<ScriptModel>((observer: Observer<ScriptModel>) => {
         
//         let existingScript = this.scripts.find(function(s) { return s.name === script.name});
         let existingScript = this.scripts.find(s => s.name == script.name);

         // Complete if already loaded
         if (existingScript && existingScript.loaded) {
            observer.next(existingScript);
            observer.complete();
         }
         else {
            // Add the script
            this.scripts = [...this.scripts, script];

            // Load the script
            let scriptElement = document.createElement("script");
            scriptElement.type = "text/javascript";
            scriptElement.src = script.src;
            scriptElement.async = true;

            //console.log('load() adding script: ' + script.src);

            scriptElement.onload = () => {
               script.loaded = true;
               observer.next(script);
               observer.complete();
            };

            scriptElement.onerror = (error: any) => {
               observer.error("Couldn't load script " + script.src);
            };

            document.getElementsByTagName('body')[0].appendChild(scriptElement);
         }
      });
   }

   public init() {
      return this.load({ name: 'SproutVideo', src: 'https://c.sproutvideo.com/player_api.js', loaded: false });
   }


}

export interface ScriptModel {
   name: string,
   src: string,
   loaded: boolean
}

