declare global {
    interface Window {
        env: any
    }
}    

export const environment = {
    production: window.env.production,
    environmentName: window.env.envionmentName,
    googleTagManagerKey: window.env.googleTagManagerKey,
    appInsights: {
        instrumentationKey: window.env.appInsightsInstrumentationKey
    },
    reCaptchaOn: window.env.reCaptchaOn,
    reCaptchaSiteKey: window.env.reCaptchaSiteKey,
    loginSiteBaseUrl: window.env.loginSiteBaseUrl,
    siteBaseUrl: window.env.siteBaseUrl,
    webApiUrl: window.env.webApiUrl,
    sgcLoginUrl: window.env.sgcLoginUrl,
    cdn: window.env.cdn,
};
