import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ICountry } from '../models/country.model';

export class VATValidator {

    static Validate(country: ICountry|undefined): ValidatorFn {
        return (ctrl: AbstractControl): ValidationErrors | null => {

            if (!country || !ctrl.value) {
                return null;
            }

            // If there is no VAT format specified, fallback on universal VAT format
            if (!country.vatFormat) {
                const regex = new RegExp('^[A-Za-z]{2,4}(?=.{2,15}$)[-_\s0-9]*(?:[a-zA-Z][-_\s0-9]*){0,4}$');
                return regex.test(ctrl.value)
                    ? null
                    : { invalid_format: true };
            }

            let value: string = ctrl.value.toUpperCase();
            const prefix = country.vatPrefix ?? country.countryCode;

            // Strip prefix if it is present
            if (value.startsWith(prefix))
                value = value.slice(prefix.length);

            const regex = new RegExp(country.vatFormat, "i");

            return regex.test(value)
                ? null
                : { invalid_format: true };
        }
    }
}
