<div class="container pl-0 pl-md-3">
    <form #reviewForm=ngForm [formGroup]="step4Form" (ngSubmit)="next($event)">
        <div class="container py-0">
            <div class="row">
                <div class="col-12">
                    <header class="local_header" id="localHeader">
                        <h1>{{content.header}}</h1>
                    </header>
                </div>
            </div>
        </div>

        <div class="container pl-0 pl-md-3">
            <div class="row">
                <div class="col pr-0 pr-md-2"><progress-bar [activeStep]="4"></progress-bar></div>
            </div>
            <div class="content-container">
                <div class="row pt-5 pb-3">
                    <div class="col">
                        <div class="section-subheader-redesign">{{content.subHeader}}</div>
                    </div>
                </div>
                <div class="row pt-1 pb-4">
                    <div class="col section-header">ORDER SUMMARY</div>
                </div>

                <!-- start spinner div ********** -->
                <div *ngIf="!showSpinner">

                    <div class="row order-review-header pt-3">
                        <div class="col-12 col-lg-7 pr-0">
                            <div class="row">
                                <div class="col-3 border-bottom border-right pb-2">PRODUCT NAME</div>
                                <div class="col-1 border-bottom border-right pl-0 pr-1 text-center"><abbr title="Quantity">QTY</abbr></div>
                                <div class="col-3 border-bottom border-right">UNIT PRICE</div>
                                <div class="col-2 border-bottom border-right pl-0 pr-1 text-center text-truncate">DISCOUNT</div>
                                <div class="col-3 border-bottom text-right">TOTAL COST</div>
                            </div>
                        </div>
                        <div class="col-md-5 d-none d-lg-block"></div>
                    </div>
                    <div class="row order-review pb-4" *ngFor="let li of salesQuote.lineItems">
                        <div class="col-12 col-lg-7 pr-0">
                            <div class="row">
                                <div class="col-3 border-right" [innerHtml]="li.productName"></div>
                                <div class="col-1 border-right pl-0 pr-1 text-center">{{li.quantity}}</div>
                                <div class="col-3 border-right">{{currencyText(li.salesPrice)}}</div>
                                <div class="col-2 border-right pl-0 pr-1 text-center">{{currencyText(totalDiscount)}}</div>
                                <div class="col-3 text-right">{{currencyText(li.quantity * li.salesPrice - li.discountAmount)}}</div>
                            </div>
                        </div>
                        <div class="col-md-5 d-none d-lg-block"></div>
                    </div>
                    <div class="row order-review pb-2">
                        <div class="col-12 col-lg-7 pr-0">
                            <div class="row">
                                <div class="col-9 text-right">Order Subtotal excluding VAT/sales tax:</div>
                                <div class="col-3 text-right">{{currencyText(salesQuote.subTotal)}}</div>
                            </div>
                        </div>
                        <div class="col-md-5 d-none d-lg-block"></div>
                    </div>

                    <div class="row order-review">
                        <div class="col-12 col-lg-7 pr-0">
                            <div class="row">
                                <div class="col-9 border-bottom text-right" *ngIf="!salesQuote.isEstimated">VAT/sales tax total:</div>
                                <div class="col-9 border-bottom text-right" *ngIf="salesQuote.isEstimated">Estimated VAT/sales tax total:</div>
                                <div class="col-3 text-right border-bottom pb-3">{{currencyText(salesQuote.salesTax)}}</div>
                            </div>
                        </div>
                        <div class="col-md-5 d-none d-lg-block"></div>
                    </div>
                    <div class="row pt-3 pb-4">
                        <div class="col-12 col-lg-7 pr-0">
                            <div class="row">
                                <div class="col-12 col-md-5 pb-2 pb-md-0"><a [routerLink]="" (click)="togglePromo()" class="std-primary-link" style="font-size: 14px !important;" [hidden]="showPromoCodeLink">Have a promotional code?</a></div>
                                <div class="col-9 col-md-4 text-right" style="font: 15px 'OpenSans-Semibold'; color: #528c9c;" *ngIf="!salesQuote.isEstimated">Total:</div>
                                <div class="col-9 col-md-4 text-right" style="font: 15px 'OpenSans-Semibold'; color: #528c9c;" *ngIf="salesQuote.isEstimated">Estimated Total:</div>
                                <div class="col-3 text-right" style="font: 15px 'OpenSans-Semibold'; color: #528c9c;">{{currencyText(salesQuote.total)}}</div>
                            </div>
                        </div>
                        <div class="col-md-5 d-none d-lg-block"></div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-12 col-md-6 pr-0" [hidden]="!showPromoCodeLink">
                            <div class="row">
                                <div class="col-6 pr-1">

                                    <input formControlName="applyDiscount" type="text" id="promotionalCode" [(ngModel)]="sourceCode" placeholder="Enter promotional code" class="form-input" maxlength="50" autocomplete="off" [readonly]="salesQuote?.isSourceCodeDiscountApplied" />
                                    <div *ngIf="discountErrorMessage.length > 0"><label class="is-invalid-message">Invalid promo code.</label></div>

                                </div>

                                <div [ngClass]="{'col-6': showApply, 'pl-0': true}" [hidden]="!showApply">
                                    <button class="btn-tpl-lg btn-orange-primary text-uppercase has-promise-spinner" [promiseBtn]="isGeneratingQuote" (click)="calculateDiscount()" [disabled]="sourceCode.trim()==='' || isGeneratingQuote" id="applyDiscount">
                                        <span class="button-text">Apply</span>
                                    </button>
                                </div>
                                <div [ngClass]="{'col-6': !showApply, 'pl-0': true}" [hidden]="showApply">
                                    <button class="btn-tpl-lg btn-orange-primary float-left text-uppercase has-promise-spinner" [promiseBtn]="isGeneratingQuote" style="width: 120px; margin-left: 1px !important;" (click)="clearDiscount()" [disabled]="sourceCode.trim()==='' || isGeneratingQuote">
                                        <span class="button-text">Remove</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'col-md-12':!showPromoCodeLink, 'col-md-6':showPromoCodeLink, 'd-none':showPromoCodeLink, 'd-lg-block':showPromoCodeLink}" [ngStyle]="{ 'height': !showPromoCodeLink?'37px' : '15px'  }"></div>
                    </div>
                    <div class="row">
                        <div class="col pr-0"><a [routerLink]="" (click)="togglePromo()" class="std-primary-link" style="font-size: 14px !important; font-weight: 700;" [hidden]="!showPromoCodeLink">Hide promotional code</a></div>
                    </div>
                </div>
                <div class="container">
                    <div *ngIf="showSpinner" class="row">
                        <div class="col">
                            <div class="spinner-container text-center" *ngIf="showSpinner">
                                <span class="spinner-border custom-spinner"></span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!showSpinner" class="row">
                        <div class="col-lg-7 d-none d-lg-block"></div>
                        <div class="col col-md-5 text-center text-md-right">
                            <button class="btn-tpl-lg btn-orange-primary has-promise-spinner" [promiseBtn]="nextSpinner" type="submit">
                                <span *ngIf="!isFullDiscount" class="button-text">NEXT</span>
                                <span *ngIf="isFullDiscount" class="button-text">SUBMIT</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>