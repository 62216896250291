import { FormGroup, FormControl, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';


let exp: { [name: string]: RegExp } = {};
exp.US = /^(\d{5}(-\d{4})?)$/;
exp.CA = /^([A-Z]\d[A-Z] ?\d[A-Z]\d)$/i;

export class PostalCodeValidator {
    
    static Validate(countryCode: string): ValidatorFn {
        return (ctrl: AbstractControl): ValidationErrors | null => {

            const cc = ctrl as FormControl;
            if (countryCode && cc?.value) {
                const regex = exp[countryCode];
                if (regex) {
                    var success = regex?.test(cc?.value);
                    if (!success) {
                        //return { postal_code_bad_format: true, message: 'Zip code is not valid. Please ensure formatting is correct e.g., 12345-6789' };
                        return {
                            postal_code : {
                               postal_code_bad_format: true,
                               message: 'Zip code is not valid. Please ensure formatting is correct e.g., 12345-6789'
                            }
                        };

                    } 

                }

            }
            return null;
        }
    }
}
