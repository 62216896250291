// Angular & 3rd party
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppService } from './app.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import * as bootstrap from 'bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input'
import { RECAPTCHA_V3_SITE_KEY, RECAPTCHA_BASE_URL, RecaptchaV3Module } from "ng-recaptcha";

// Services
import { ApplicationInsightsService } from './common/services/application-insights.service';
import { GoogleAnalyticsService } from './common/services/google-analytics.service';
import { ExternalScriptsService } from './common/services/external-scripts.service';
import { DataService } from './common/services/data.service';
import { environment } from '../environments/environment'

import { ContentService } from './common/services/content.service';
import { RegistrationService } from './common/services/registration.service';

// Directives and Pipes
import { DateFormatPipeMMMDDYYYY } from './common/pipes/date-format.pipe';
import { SafeHtmlPipe } from './common/pipes/safe-html.pipe';


// Components
import { AlertModule } from './common/services/alert-service';   // https://github.com/cornflourblue/angular-9-alert-notifications
import { AppComponent } from './app.component';
import { ErrorComponent } from './error/error.component';
import { NotFoundComponent } from './error/not-found.component';
import { SiteHeaderComponent } from './_layout/site-header/site-header.component';
import { SiteFooterComponent } from './_layout/site-footer/site-footer.component';
import { RegistrationComponent } from './registration/registration.component';
import { AccountComponent } from './registration/account/account.component';
import { SecurityComponent } from './registration/security/security.component';
import { OrganizationComponent } from './registration/organization/organization.component';
import { ReviewComponent } from './registration/review/review.component';
import { ConfirmationComponent } from './registration/confirmation/confirmation.component';
import { SgcConfirmationComponent } from './registration/sgc-confirmation/sgc-confirmation.component';
import { ProgressBarComponent } from './registration/progress-bar/progress-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RequestedWithInterceptor } from './common/http-interceptors/requested-with-interceptor';
import { MbcKeydownDirective } from './common/directives/mbc-keydown/mbc-keydown.directive';


@NgModule({
    declarations: [
        DateFormatPipeMMMDDYYYY,
        AppComponent,
        ErrorComponent,
        NotFoundComponent,
        SiteHeaderComponent,
        SiteFooterComponent,
        RegistrationComponent,
        AccountComponent,
        SecurityComponent,
        OrganizationComponent,
        ReviewComponent,
        ConfirmationComponent,
        SgcConfirmationComponent,
        ProgressBarComponent,
        SafeHtmlPipe,
        MbcKeydownDirective
    ],
    imports: [
        Angular2PromiseButtonModule.forRoot({
            // your custom config goes here
            spinnerTpl: '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>',
            // disable buttons when promise is pending
            disableBtn: true,
            // the class used to indicate a pending promise
            btnLoadingClass: 'is-loading',
            // only disable and show is-loading class for clicked button, 
            // even when they share the same promise
            handleCurrentBtnOnly: false
        }),
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AlertModule,
        BrowserAnimationsModule,
        NgxIntlTelInputModule,
        RecaptchaV3Module
    ],
    providers: [
        ExternalScriptsService,
        AppService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestedWithInterceptor,
            multi: true
        },
        ApplicationInsightsService,
        GoogleAnalyticsService,
        DataService,
        {
            provide: APP_INITIALIZER,
            useFactory: (contentService: ContentService) => () => contentService.initializeConfiguration(),
            multi: true,
            deps: [ContentService]
        },
        ContentService, //TODO: remove ??
        RegistrationService,
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.reCaptchaOn ? environment.reCaptchaSiteKey : null
        },
        {
            provide: RECAPTCHA_BASE_URL,
            useValue: "https://recaptcha.net/recaptcha/api.js"
        },        
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
