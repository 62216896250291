import { Injectable } from "@angular/core"
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError, EMPTY } from 'rxjs';
import { ApplicationInsightsService } from './common/services/application-insights.service';
import { User } from './common/models/user.model';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: "root"
})
    
export class AppService {
    user = new User;
    
    constructor(
        private applicationInsightsService: ApplicationInsightsService,
        private router: Router
    ) {}

    public handleError(exception: any) {
        if (exception instanceof HttpErrorResponse && exception.status === 401) {
            //var target = window.location.pathname;
            //window.location.href = "/?target=" + encodeURIComponent(target);
            return EMPTY;
        } else {
            // Log error in App Insights if we can connect to it
            if (typeof this.applicationInsightsService !== 'undefined')
                this.applicationInsightsService.logException(exception);

            if (environment.production)
                return throwError(exception.message);
            else
                return throwError(exception);
        }
    }

    public handleErrorWithRedirect(exception: any) {
        if (exception instanceof HttpErrorResponse && exception.status === 401) {
            //var target = window.location.pathname;
            //window.location.href = "/?target=" + encodeURIComponent(target);
        } else {
            // Log error in App Insights if we can connect to it
            if (typeof this.applicationInsightsService !== 'undefined')
                this.applicationInsightsService.logException(exception);

            this.router.navigate(['/error']);
        }
    }
}
