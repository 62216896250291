<div class="container">
    <div class="container py-0">
        <div class="row">
            <div class="col-12">
                <header class="local_header" id="localHeader">
                    <h1>{{content.header}}</h1>
                </header>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md pr-0 pr-md-2"><progress-bar [activeStep]="1"></progress-bar></div>
        </div>
        <div class="container content-container">
            <div class="row pt-3 pb-4">
                <div class="col-md section-subheader-redesign"><div [innerHTML]="content.subHeader"></div></div>
                <div class="col-md d-none d-lg-block"></div>
            </div>
            <div class="row">
                <div class="col-xl-7 col-lg-8 col-md-12">
                    <div class="container pt-0 pb-4">
                        <div *ngIf="!isCPPWebAccountVerified" class="row CppLogin">
                            <div class="col-md-9 col-sm-12">
                                <span style="font-family: OpenSans-Bold">Have a Myers-Briggs Company (CPP.com) account?</span><br />
                                <span>Log in before you register:</span><br />
                            </div>
                            <div class="col-md-3 text-right">
                                <div class="CppLogin d-inline-block p-0">
                                    <div class="btn btn-teal-primary" style="min-width: 7.25rem;" (click)="showShopLoginPopup($event)" (keydown.enter)="showShopLoginPopup($event)" tabindex="0">
                                        <span style="position: relative; top: -5px;">LOG IN</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <!-- Content here -->
                </div>
            </div>
            <form #accountForm="ngForm" (ngSubmit)="next(accountForm)" [formGroup]="step1Form">
                <div class="row pb-3">
                    <div class="col-md">
                        <fieldset formGroupName="address">
                            <div class="form-control-registration">
                                <label class="input-section-label" for="countryCode" [class.is-invalid-label]="countryCodeCtrl.errors?.required && accountForm.submitted">Organization Country</label>
                                <select id="countryCode" formControlName="countryCode" class="form-select" style="cursor: pointer;" data-toggle="tooltip" data-html="true" title="<span class='floating-tooltip'>Please select the country in which your organization is physically located. If you are not part of an organization, select the country in which you reside.<br /><br /><em>Note:</em> You <strong>will not</strong> be able to edit your country selection after you click <strong>Next</strong>.</span>">
                                    <option [ngValue]="null" [disabled]="true">Select a country</option>
                                    <option *ngFor="let prf of countries" [ngValue]="prf.countryCode">
                                        {{ prf.countryName }}
                                    </option>
                                </select>
                                <input id="countryName" formControlName="countryName" type="hidden" />
                            </div>
                            <div *ngIf="countryCodeCtrl.errors?.required && accountForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                        </fieldset>

                    </div>
                    <div class="col-md d-none d-lg-block"></div>
                </div>
                <div class="row pb-3">
                    <div class="col-md">
                        <label class="input-section-label" for="organizationName" [class.is-invalid-label]="organizationNameCtrl.errors?.required && accountForm.submitted">Organization name</label>
                        <input type="text" id="organizationName" formControlName="organizationName" placeholder="Enter your organization's name" class="form-input" maxlength="100" autocomplete="off" data-toggle="tooltip" data-html="true" title="<span class='floating-tooltip'>Your organization name will be used to differentiate between multiple accounts, if applicable.<br /><br />If you are not part of an organization or do not own your own company, use your name.</span>" />
                        <div *ngIf="organizationNameCtrl.errors?.required && accountForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                        <div *ngIf="organizationNameCtrl.errors?.whitespace && accountForm.submitted"><label class="is-invalid-message">Must contain more than just whitespace.</label></div>
                    </div>
                    <div class="col-md d-none d-lg-block"></div>
                </div>
                <fieldset formGroupName="owner">
                    <div class="row pb-3">
                        <div class="col-md">
                            <label class="input-section-label" for="firstName" [class.is-invalid-label]="firstNameCtrl.errors?.required && accountForm.submitted">First name</label>

                            <input type="text" id="firstName" formControlName="firstName" placeholder="Enter your first name" class="form-input" maxlength="20" autocomplete="off" />
                            <div *ngIf="firstNameCtrl.errors?.required && accountForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                        </div>
                        <div class="col-md d-none d-lg-block"></div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-md">
                            <label class="input-section-label" for="lastName" [class.is-invalid-label]="lastNameCtrl.errors?.required && accountForm.submitted">Last name</label>

                            <input type="text" id="lastName" formControlName="lastName" placeholder="Enter your last name" class="form-input" maxlength="20" autocomplete="off" />
                            <div *ngIf="lastNameCtrl.errors?.required && accountForm.submitted"><label id="lastNameError" class="is-invalid-message">This field is required.</label></div>
                        </div>
                        <div class="col-md d-none d-lg-block"></div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-md">
                            <label class="input-section-label" for="emailAddress" [class.is-invalid-label]="emailAddressCtrl.errors && accountForm.submitted || emailAddressCtrl.errors?.pattern">Email address</label>

                            <input type="email" id="emailAddress" formControlName="emailAddress" placeholder="Enter your email address" class="form-input" maxlength="75" autocomplete="off" data-toggle="tooltip" data-html="true" title="<span class='floating-tooltip'>You will use your email address to log in to your Elevate<sup>&reg;</sup> account after it's created.<br /><br /><em>Note:</em> If you have a Myers-Briggs Company (CPP.com) account, please log in by clicking the <strong>Log In</strong> button above before you continue registering.</span>" />

                            <div *ngIf="emailAddressCtrl.errors?.required && accountForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                            <div *ngIf="emailAddressCtrl.errors?.pattern"><label class="is-invalid-message">Email must be a valid email address.</label></div>
                        </div>
                        <div class="col-md d-none d-lg-block"></div>
                    </div>
                    <div class="row pb-3" [hidden]="!selfGuidedCertification">
                        <div class="col-md">
                            <div class="form-control-registration">
                                <label class="input-section-label" for="onboardingValue" [class.is-invalid-label]="onboardingValueCtrl.errors?.required && accountForm.submitted">So that we can provide you with the best possible experience, please select the statement that best describes you:</label>
                                <select id="onboardingValue" formControlName="onboardingValue" class="form-select" style="cursor: pointer;" data-toggle="tooltip" data-html="true" title="<span class='floating-tooltip'>So that we can provide you with the best possible experience, please select the statement that best describes you:</span>">
                                    <option value="" disabled>Select a statement</option>
                                    <option *ngFor="let onboardingReason of onboardingReasons" [ngValue]="onboardingReason.onboardingValue">
                                        {{ onboardingReason.onboardingText }}
                                    </option>
                                </select>
                            </div>
                            <div *ngIf="onboardingValueCtrl.errors?.required && accountForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                        </div>
                        <div class="col-md d-none d-lg-block"></div>
                    </div>
                    <div class="row pb-5">
                        <div class="col-md">
                            <label class="input-section-label" style="line-height: 1;" [class.is-invalid-label]="allowEmailCommunicationCtrl.errors?.required && accountForm.submitted">Email communication preference</label>
                            <div style="width: 100%;">
                                <label class="custom-radio-container">
                                    <input class="userradio" type="radio" value="Y" formControlName="allowEmailCommunication" /><span>Yes, I'd like to receive emails from The Myers-Briggs Company (Free tools, best practices and new product announcements).</span>
                                    <span class="custom-radiomark" mbcKeydown (mbcOnKeydown)="allowEmailCommunicationCtrl.setValue('Y')" ></span>
                                </label>
                                <label class="custom-radio-container">
                                    <input class="userradio" type="radio" value="N" formControlName="allowEmailCommunication" /><span>No, I do not want to receive emails from The Myers-Briggs Company.</span>
                                    <span class="custom-radiomark" mbcKeydown (mbcOnKeydown)="allowEmailCommunicationCtrl.setValue('N')"></span>
                                </label>
                                <div *ngIf="allowEmailCommunicationCtrl.errors?.required && accountForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                            </div>
                        </div>
                        <div class="col-md d-none d-lg-block"></div>
                    </div>
                </fieldset>
                <div class="row">
                    <div class="col-md">
                        <div *ngIf="(chkTermsCtrl.errors?.required || chkPrivacyPolicyCtrl.errors?.required || chkAgreeAgeCtrl.errors?.required) && accountForm.submitted">
                            <label class="is-invalid-message">
                                All boxes below must be checked in order for you to continue.
                            </label>
                        </div>
                        <div>
                            <label class="register-check-container" for="chkTerms">
                                <input type="checkbox" id="chkTerms" formControlName="chkTerms"  />
                                <span class="agreement-checkmark" mbcKeydown (mbcOnKeydown)="toggleCheckbox(chkTermsCtrl)"></span>
                                I agree to the Elevate <a class="std-primary-link" style="font-size: 14px !important; cursor: pointer" (click)="showLicenseAgreement()" (keydown.enter)="showLicenseAgreement()" tabindex="0" >Terms of Use</a>.
                            </label>
                        </div>
                        <div>
                            <label class="register-check-container" for="chkPrivacyPolicy">
                                <input type="checkbox" id="chkPrivacyPolicy" formControlName="chkPrivacyPolicy" />
                                <span class="agreement-checkmark" mbcKeydown (mbcOnKeydown)="toggleCheckbox(chkPrivacyPolicyCtrl)"></span>
                                I agree to the Elevate <a class="std-primary-link" style="font-size: 14px !important;" href="https://links.themyersbriggs.com/9saek9kk" target="_blank">Privacy Policy</a>.
                            </label>
                        </div>
                        <div>
                            <label class="register-check-container" for="chkAgreeAge">
                                <input type="checkbox" id="chkAgreeAge" formControlName="chkAgreeAge" />
                                <span class="agreement-checkmark" mbcKeydown (mbcOnKeydown)="toggleCheckbox(chkAgreeAgeCtrl)"></span>
                                I confirm that I am at least 16 years old.
                            </label>
                        </div>
                    </div>
                    <div class="col-md d-none d-lg-block"></div>
                </div>
                <div class="row text-center text-md-right pt-4 pt-md-3">
                    <div class="col-md">
                        <button class="btn-tpl-lg btn-orange-primary has-promise-spinner" [promiseBtn]="nextSpinner" type="submit">
                            <span class="button-text">NEXT</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!--modals-->
<div class="modal fade" id="countryNotAuthorizedPopUp" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="countryNotAuthorizedPopUptitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title">
                    <div id="countryNotAuthorizedPopUptitle" class="modal-header-redesign" style="line-height: 1.4;">You Cannot Register on Elevate<sup>&reg;</sup></div>
                </div>
                <a class="modal-close pointer" id="countryNotAuthorizedPopUpclose" data-dismiss="modal" aria-label="Close">&nbsp;</a>
            </div>
            <div class="modal-body">
                <div style="padding-top: 20px; line-height: 1.4;">
                    <div style="padding-bottom: 20px;">
                        Unfortunately, The Myers-Briggs Company does not do business in your country or region.
                    </div>
                    <div>
                        Please contact our Global Sales team for additional information <span style="white-space: nowrap">+1 800 624 1765</span>.
                    </div>
                </div>
            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="countryCannotRegisterPopUp" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="countryCannotRegisterPopUptitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title"><div id="countryCannotRegisterPopUptitle" class="modal-header-redesign" style="line-height: 1.4;">Contact a Local Representative</div></div>
                <a class="modal-close pointer" data-dismiss="modal" aria-label="Close">&nbsp;</a>
            </div>
            <div class="modal-body">
                <div style="width: 100%; overflow: hidden;">
                    <p>
                        <label>
                            To use The Myers-Briggs Company's products you must contact a representative in your country or region.
                            Click on a region below to see a list of representatives who can help you meet your people development needs.
                        </label>
                    </p>
                    <div>
                        <p><label><a href="https://www.themyersbriggs.com/en-US/Select-Country#americas">Americas</a></label></p>
                        <p><label><a href="https://www.themyersbriggs.com/en-US/Select-Country#asia_pacific">Asia Pacific</a></label></p>
                        <p><label><a href="https://www.themyersbriggs.com/en-US/Select-Country#europe_and_central_asia">Europe and Central Asia</a></label></p>
                        <p><label><a href="https://www.themyersbriggs.com/en-US/Select-Country#middle_east_and_africa">Middle East and Africa</a></label></p>
                    </div>
                </div>
                <div class="div-mb2">
                    <label>
                        <strong style="color: #528c9c">Have questions?</strong><br />
                        Contact our Global Sales team at +1 800 624 1765.
                    </label>
                </div>
            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="openCPPAccountLoginPopup" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="openCPPAccountLoginPopuptitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form [formGroup]="loginForm" (ngSubmit)="validateWebAccount()">
                <div class="modal-header">
                    <div class="modal-title">
                        <div id="openCPPAccountLoginPopuptitle" class="modal-header-redesign" style="line-height: 1.4;">Log In</div>
                    </div>
                    <a class="modal-close pointer" data-dismiss="modal" aria-label="Close" tabindex="0" (keydown.enter)="validateWebAccount()">&nbsp;</a>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <div id="cppAccountValidation" class="validationMessage modal-redesign-error" style="display: none;"></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label>
                                    <span>Entering your <span style="font-family: 'OpenSans-bold';">Myers-Briggs Company</span> (CPP.com) login information below will save you time when registering on Elevate<sup>&reg;</sup>.</span>
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label class="input-section-label">Username</label>
                                <input type="text" id="userName" formControlName="userName" class="form-input" maxlength="50" autocomplete="off" />
                            </div>
                        </div>
                        <div class="row pb-4">
                            <div class="col">
                                <label class="input-section-label">Password</label>
                                <input type="password" id="password" formControlName="password" class="form-input" maxlength="50" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-center">
                    <input type="submit" id="submitlogin" [disabled]="!loginForm.valid" class="btn-tpl-lg btn-orange-primary" value="LOG IN" />
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal" id="verifyAccount" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="verifyAccounttitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <form #vaForm="ngForm" [formGroup]="verifyAccountForm" (ngSubmit)="validateExistingAccount()">


                <div class="modal-header">
                    <div class="modal-title">

                        <div id="verifyAccounttitle" class="modal-header-redesign" style="line-height: 1.4;">Verify Your Account</div>
                    </div>

                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row pb-4">

                            <div class="col">To continue registering with the email address <b>{{step1Form?.get('owner.emailAddress')?.value}}</b>, you must first verify your existing account by entering your password.</div>
                        </div>
                        <div class="row">

                            <div class="col">
                                <div style="padding-top: 5px; padding-bottom: 34px;">
                                    <div>
                                        <label class="input-section-label">Password</label>
                                    </div>

                                    <div style="padding-bottom: 5px;">

                                        <input type="password" id="verifyPassword" formControlName="verifyPassword" class="form-input" maxlength="50" autocomplete="off" />
                                        <div *ngIf="!vaForm.form.controls['verifyPassword']?.valid && vaForm.submitted"><label class="is-invalid-message">This field is required.</label></div>
                                    </div>

                                    <div id="passwordValidation" class="validationMessage" style="display: none;"></div>

                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div style="font-size: 14px !important;">
                                    <div style="padding-bottom: 5px; font: 700 14px OpenSans-Regular; color: #666666;">Need Help?</div>
                                    <div style="padding-bottom: 5px; margin-left: 15px">
                                        <a (click)="forgotPasswordRedirect(vaForm)" class="std-primary-link">Forgot password?</a>
                                    </div>
                                    <div style="padding-bottom: 34px; margin-left: 15px">
                                        <a [routerLink]="" (click)="useDifferentEmail(vaForm)" class="std-primary-link">Use a different email address</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <input type="submit" class="button btn-tpl-lg btn-orange-primary" value="Verify Account" />
                </div>

            </form>

        </div>
    </div>
</div>
<div class="modal fade" id="lockedAccount" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="lockedAccounttitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pb-2">
                <div class="modal-title"><div id="lockedAccounttitle" class="modal-header-redesign">Locked Out</div></div>

            </div>
            <div class="modal-body py-0">
                <div style="padding-top: 10px; padding-bottom: 15px;">
                    You have exceeded the allowed number of password attempts.
                </div>
                <div style="padding-bottom: 20px;">
                    To continue registering on Elevate, you will need to reset your account password or use a different email address.
                </div>
            </div>
            <div class="modal-footer pt-0" style="text-align: center;">
                <div class="col pb-sm-1"><input type="button" class="button btn-tpl-lg btn-orange-primary" id="accountLock" (click)="lockedAccountReset()" style="margin-right: 7px;" value="Reset Account Password" /></div>
                <div class="col pb-sm-1"><input type="button" class="button btn-tpl-lg btn-orange-primary" id="useanother" (click)="lockedAccountNewEmail()" value="Use Different Email" /></div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="redirectToExistingAccount" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="verifyAccounttitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <form #vaForm="ngForm" [formGroup]="verifyAccountForm" (ngSubmit)="redirectToExistingAccount(vaForm)">


                <div class="modal-header">
                    <div class="modal-title">

                        <div id="verifyAccounttitle" class="modal-header-redesign" style="line-height: 1.4;">Log In to Your Account</div>
                    </div>

                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row pb-4">

                            <div class="col">You already have an Elevate&reg; account registered with the email address <b>{{step1Form?.get('owner.emailAddress')?.value}}</b>. Would you like to login using this account?</div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div style="font-size: 14px !important;">
                                    <div style="padding-bottom: 5px; font: 700 14px OpenSans-Regular; color: #666666;">Need Help?</div>
                                    <div style="padding-bottom: 34px; margin-left: 15px">
                                        <a [routerLink]="" (click)="useDifferentEmailForLimitedAccount(vaForm)" class="std-primary-link">Use a different email address</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <input type="submit" class="button btn-tpl-lg btn-orange-primary" value="Continue to Log In" />
                </div>

            </form>

        </div>
    </div>
</div>
<div class="modal fade" id="shopLoginForm" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="shopLoginFormtitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form #shopLoginForm="ngForm" [formGroup]="loginForm" (ngSubmit)="validateShopLogin()">
                <div class="modal-header">
                    <div class="modal-title">
                        <div id="shopLoginFormtitle" class="modal-header-redesign" style="line-height: 1.4;">Log In</div>
                    </div>
                    <a class="modal-close pointer" data-dismiss="modal" aria-label="Close" (click)="closeShopLoginPopup(loginForm, shopLoginForm)" mbcKeydown (mbcOnKeydown)="closeShopLoginPopup(loginForm,shopLoginForm)">&nbsp;</a>
                </div>
                <div class="modal-body pb-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <div id="shopLoginFormError" class="validationMessage modal-redesign-error" style="display: none;"></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label>
                                    <span>Entering your <span style="font-family: 'OpenSans-bold';">Myers-Briggs Company</span> (CPP.com) login information below will save you time when registering on Elevate<sup>&reg;</sup>.</span>
                                </label>
                            </div>
                        </div>
                        <div class="row pb-4">
                            <div class="col">
                                <label class="input-section-label">Username</label>
                                <input type="text" formControlName="userName" class="form-input" maxlength="50" autocomplete="off" id="shopLoginFormUserName" />
                            </div>
                        </div>
                        <div class="row pb-4">
                            <div class="col">
                                <label class="input-section-label">Password</label>
                                <input type="password" formControlName="password" class="form-input" maxlength="50" autocomplete="off" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div style="padding-bottom: 5px;">
                                    <div></div>
                                    <label class="validationMessage" style="display: none;"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-center pt-3">
                    <input type="submit" class="btn-tpl-lg btn-orange-primary" value="LOG IN" />
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="selectProfileModal" tabindex="-1" aria-labelledby="selectProfileModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header" style="border-style: none;">
                <div class="modal-title">
                    <div class="modal-header-redesign" style="line-height: 1.4;">Select Your Account</div>
                </div>
                <a class="modal-close pointer" data-dismiss="modal" aria-label="Close">&nbsp;</a>
            </div>
            <div class="modal-body profileSelect">
                <div style="padding-top: 20px; line-height: 1.4;">
                    <form #myForm="ngForm" (submit)="selectProfile(myForm)" novalidate>
                        <ul id="orgList">
                            <li *ngFor="let profile of memberProfiles; index as i;">
                                <input class="profile-radio" id="{{profile.tenantId}}" type="radio" value="{{profile.tenantId}}" name="tenantId" ngModel required>
                                <label for="{{profile.tenantId}}">{{profile.displayText}}</label>
                            </li>
                        </ul>
                        <div style="padding-top: 20px; text-align: center">
                            <button type="submit" class="button btn-tpl-lg btn-orange-primary">Select</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>
</div>