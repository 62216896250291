<header class="sub-nav-header">
    <div class="container-fluid">
        <div class="container">
            <nav class="navbar navbar-expand-sm navbar-light d-block">
                <div class="navbar-brand">
                    <img class="logo" src="assets/img/mb-elevate-logo.svg" tabindex="0" alt="The Myers-Briggs Company">
                </div>
                <div class="contact-us d-none d-sm-block float-right">
                    <button class="btn-tpl-lg btn-orange-secondary" (click)="contactClick()">Contact Us</button>
                </div>
                <button class="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon pointer"></span>
                </button>
                <div class="collapse navbar-collapse float-right" id="navbarNav">
                    <ul class="navbar-nav">
                        <li class="nav-item d-sm-none">
                            <a class="nav-link pointer" (click)="contactClick()">
                                Contact Us
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>