import { IMemberLoginModel } from '../models/member-login-model.model'
import { IMemberProfileModel } from "../models/member-profile-model.model"
import { IPhoneNumber } from './phone-number.model';
import { ISupportsLegacyWebAccountCredentials } from '../models/supports-legacy-web-account-credentials';

export interface IOrganizationOwnerModel extends ISupportsLegacyWebAccountCredentials {
    allowEmailCommunication: string | boolean;
    emailAddress: string;
    firstName: string;
    shopCredentials: IMemberLoginModel | null;
    isConsultant: string | boolean;
    lastName: string;
    password: string;
    phoneNumber: IPhoneNumber | string;
    profile: IMemberProfileModel | null;
    title: string;
    userName: string;
    onboardingValue: string;
}
export class OrganizationOwnerModel implements IOrganizationOwnerModel {
    allowEmailCommunication: string | boolean = '';
    emailAddress = '';
    firstName = '';
    shopCredentials: IMemberLoginModel | null = null;
    isConsultant: string | boolean = true;
    lastName = '';
    password = '';
    phoneNumber: IPhoneNumber | string = '';
    profile: IMemberProfileModel | null = null;
    title = '';
    userName = '';
    onboardingValue = '';
}