export interface ISalesQuoteLineItem {
    productCode: string;
    productName: string;
    quantity: number;
    packageCode: string;
    listPrice: number;
    salesPrice: number;
    discountAmount: number;
    discountPercentage: number;
}
export class SalesQuoteLineItem implements ISalesQuoteLineItem {
    productCode = "";
    productName = "";
    quantity = 1;
    packageCode = "";
    listPrice = 10000.00;
    salesPrice = 10000.00;
    discountAmount = 0.00;
    discountPercentage = 0;
}

export interface ISalesQuoteShippingMethod {
    shippingMethodId: string;
    displayName: string;
    isUsingCustomerAccount: boolean;
    shippingCost: number;
}

export interface ISalesQuote {
    lineItems: ISalesQuoteLineItem[];
    salesQuoteId: string;
    companyCode: string;
    customerNumber: string;
    currencyCode: string;
    selectedShippingMethodId: string;
    sourceCode: string;
    isSourceCodeDiscountApplied: boolean;
    isTaxIncludedInListPrice: boolean;
    salesTax: number;
    shippingMethods: ISalesQuoteShippingMethod[];
    subTotal: number;
    total: number;
    isEstimated: boolean;
}
export class SalesQuote implements ISalesQuote {
    lineItems: ISalesQuoteLineItem[] = [new SalesQuoteLineItem()];
    salesQuoteId = "";
    companyCode = "1000";
    customerNumber = "";
    currencyCode = "USD";
    selectedShippingMethodId = "";
    sourceCode = "";
    isSourceCodeDiscountApplied = false;
    isTaxIncludedInListPrice = false;
    salesTax = 0.00;
    shippingMethods = [];
    subTotal = 195.00;
    total = 195.00;
    isEstimated = false;
}

export interface ISalesQuoteData {
    quote: ISalesQuote;
    names: { [key: string]: string };
}
