import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';  //added after
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { PasswordValidator } from '../../common/validators/passwordvalidator';

//services
import { RegistrationService } from '../../common/services/registration.service';
import { ContentService } from "../../common/services/content.service";


@Component({
    selector: 'app-step2',
    templateUrl: './security.component.html',
    styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {
    content: any;

    step2Form!: FormGroup;
    preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
    searchCountryFields: SearchCountryField[] = [SearchCountryField.Iso2, SearchCountryField.Name];
    selectedCountryIso: CountryISO = CountryISO.UnitedStates;
    numberFormat: PhoneNumberFormat = PhoneNumberFormat.National;

    constructor(private router: Router,
        private formBuilder: FormBuilder,
        private registrationService: RegistrationService,
        private contentService: ContentService) {
        this.content = this.contentService.content.security;
    }

    ngOnInit(): void {
        this.step2Form = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(8)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
            phoneNumber: ['', Validators.required]
        },
        {
            validator: Validators.compose(
                [
                    PasswordValidator.emailAddressMustNotEqualPassword('password', 'confirmPassword', this.registrationService.model.owner.emailAddress),
                    PasswordValidator.passwordsMustBeEqual('password', 'confirmPassword'),
                    PasswordValidator.passwordMustBeComplex('password', 'confirmPassword'),
                ])
            });
        if (this.registrationService.accountSetupDone) {
            this.router.navigate(["account/confirmation"]);
        }
        this.registrationService.loadRegistrationData();
        if (!this.registrationService.model.address.countryName) {
            this.router.navigate(['account/details']);
        }
        this.step2Form.patchValue(this.registrationService.model.owner);

        $('[data-toggle="tooltip"]').tooltip({
            placement: 'auto',
            html: true,
            trigger: 'hover',
            fallbackPlacement: ["right", "left", "top", "bottom"]
        });

        const phoneCountry = this.registrationService.model.address.countryCode.toLowerCase() as CountryISO;
        if (Object.values(CountryISO).includes(phoneCountry)) {
            this.selectedCountryIso = phoneCountry;
        }        
    }

    ngOnDestroy() {
        $('[data-toggle="tooltip"]').tooltip('dispose');
    }

    get passwordCtrl() { return this.step2Form.get("password") as FormControl; }
    get confirmPasswordCtrl() { return this.step2Form.get("confirmPassword") as FormControl; }
    get phoneNumberCtrl() { return this.step2Form.get("phoneNumber") as FormControl; }


    next(event: Event) {
        if (!this.step2Form.valid) {
            
            return;
        };
        const updates = { owner: this.step2Form.value };
        this.registrationService.saveRegistrationData(updates);
        this.router.navigate(['/account/organization']);
    }

}
