/*******************************************************************************
 * IMPORTANT: If there is a need to add or remove error codes, be sure to also *
 * update "/Controllers/SubscriptionController" as well.                       *
 *******************************************************************************/
export class RegistrationErrorCodes {
    public static readonly ERROR_BAD_BILLING_ADDRESS: string = "EBBA";
    public static readonly ERROR_INTERNAL_PROCESSING: string = "EIPE";
    public static readonly ERROR_INVALID_MODEL_STATE: string = "EIMS";
    public static readonly ERROR_PAYMENT_AUTH_FAILED: string = "EPAF";
    public static readonly ERROR_QUOTE_NOT_AVAILABLE: string = "EQNA";
    public static readonly ERROR_INVALID_CAPTCHA: string = "ECAP";
}