<div>
    <div class="progressTracker">
        <ol class="checkout-track-progress track-progress" [attr.data-steps]="steps.length">
            <li *ngFor="let step of steps; index as i" [ngClass]="{'active':activeStep>=i+1}" id="{{step.id}}">
                <span>
                    <p>
                        <span>Step {{i+1}}</span>
                    </p>
                    <p>
                        <span>{{step.name}}</span>
                    </p>
                </span>
            </li>
        </ol>
    </div>
</div>
