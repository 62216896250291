/* Use this service to communicate between unrelated components
 *
 * Usage: Sending component:
 *    constructor(private data: DataService)
 *    this.data.changeMessage("Send Message");
 *
 * Usage: Receiving component:
 *    constructor(private data: DataService)
 *    this.data.currentMessage.subscribe(message => {
 *         if (message === "Send Message") {
 *            // DO SOMETHING
 *         }
 *      });
 *    
 * */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {
    private messageSource = new BehaviorSubject("");
    currentMessage = this.messageSource.asObservable();

    changeMessage(message: string) {
        this.messageSource.next(message);
    }
}