import { Component, OnInit, Input } from '@angular/core';
import { RegistrationService } from '../../common/services/registration.service';
import { ProductCodes } from '../../common/models/product-codes';
import { ProgressStep } from '../../common/models/progress-step';
import { Router } from '@angular/router';

@Component({
    selector: 'progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

    @Input() activeStep: any;
    steps: ProgressStep[] = [];

    constructor(private registrationService: RegistrationService, private router: Router) {

        // This is necesary because there is no product code cookie available on the confirmation page
        const limitedAccess = this.router.url == '/account/confirmation/limited'
            ? true
            : this.registrationService.productCode === ProductCodes.limitedAccess;

        this.steps = [
            new ProgressStep('accountTab', 'Account'),
            new ProgressStep('securityTab', 'Security'),
            new ProgressStep('organizationTab', 'Organization')
        ]

        if (!limitedAccess) {
            this.steps.push(new ProgressStep('reviewOrderTab', 'Review Order'));
            this.steps.push(new ProgressStep('paymentTab', 'Payment'));
        }

        this.steps.push(new ProgressStep('confirmationTab', 'Confirmation'));
    }

    ngOnInit(): void {
        $('li.active').get(-1);
    }
    ngAfterViewInit() {
        $('li.active').removeClass('current');
        $($('li.active').get(-1) as HTMLElement).addClass('current');
    }
}
