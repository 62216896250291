import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AppService } from '../../app.service';
declare let gtag: Function;

@Injectable({
   providedIn: 'root'
})

export class GoogleAnalyticsService {

   constructor(private router: Router, private appService: AppService) {

   }

   // this.googleAnalyticsService.eventEmitter("Tutorial", "Open", this.modalOpenedCounter);
   public eventEmitter(eventLabel: string, eventAction: string, eventValue: any = null) {
      const eventName = "Click";

      gtag('event', eventName, {
         eventCategory: "Registration",
         eventAction: eventAction,
         eventLabel: eventLabel,
         eventValue: eventValue,
         personId: this.appService.user.GtmId
      });
   }

   public init() {
      this.listenForRouteChanges();

      try {
         const gtmScript1 = document.createElement('script');
         gtmScript1.innerHTML = `dataLayer = [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '` + environment.googleTagManagerKey + `');`;
         document.head.appendChild(gtmScript1);

         const gtmScript2 = document.createElement('script');
         gtmScript2.innerHTML = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${environment.googleTagManagerKey}');`;
         document.head.appendChild(gtmScript2);

         const gtmScript3 = document.createElement('noscript');
         gtmScript3.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${environment.googleTagManagerKey}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
         document.body.appendChild(gtmScript3);
      } catch (ex) {
         console.error('Error appending google analytics');
         console.error(ex);
      }
   }

   private listenForRouteChanges() {
      this.router.events.subscribe(event => {
         if (event instanceof NavigationEnd) {
            gtag('config', environment.googleTagManagerKey, {
               'page_path': event.urlAfterRedirects,
            });

            //console.log('Sending Google Analytics hit to ' + environment.googleTagManagerKey + ' for route ' + event.urlAfterRedirects);
         }
      });
   }
}
