import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from "ng-recaptcha";
import { finalize } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ExternalUrls } from '../../common/constants/external-urls';
import { NoWhitespaceValidator } from '../../common/validators/no-whitespace-validator';

//services
import { AlertService } from '../../common/services/alert-service/alert.service';
import { ContentService } from '../../common/services/content.service';
import { RegistrationService } from '../../common/services/registration.service';

//models
import { ICountry } from '../../common/models/country.model';
import { IMemberProfileModel } from '../../common/models/member-profile-model.model';
import { IOnboardingReason } from '../../common/models/onboarding-reason.model';
import { ProductCodes } from '../../common/models/product-codes';
import { IRegistrationSalesQuoteModel } from '../../common/models/self-registration-model.model';
import { IVerifyAccountOwnerProfileModel } from '../../common/models/verify-account-owner-profile-model.model';

@Component({
    selector: 'app-step1',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})

export class AccountComponent implements OnInit, OnDestroy {
    content: any;

    step1Form!: FormGroup;
    loginForm!: FormGroup;
    verifyAccountForm!: FormGroup;

    isCPPWebAccountVerified = false;
    showNotUpgraded = false;
    nextSpinner = false;
    selfGuidedCertification = false;
    limitedAccess = false;

    countries: ICountry[] = [];
    memberProfiles: IMemberProfileModel[] = [];
    onboardingReasons: IOnboardingReason[] = [];

    constructor(private router: Router, private formBuilder: FormBuilder, private registrationService: RegistrationService, private contentService: ContentService, private recaptchaV3Service: ReCaptchaV3Service, private alertService: AlertService) {
        this.selfGuidedCertification = this.registrationService.productCode === ProductCodes.selfGuidedCertification;
        this.limitedAccess = this.registrationService.productCode === ProductCodes.limitedAccess;

        const onboardingValueConditionallyRequiredValidator = (formControl: AbstractControl) => {
            if (!formControl.parent) {
                return null;
            }

            if (this.selfGuidedCertification) {
                return Validators.required(formControl);
            }
            return null;
        }

        this.step1Form = this.formBuilder.group({
            address: this.formBuilder.group({
                countryCode: [null, [Validators.required]],
                countryName: [null, [Validators.required]],
            }),
            organizationName: ['', [Validators.required, NoWhitespaceValidator()]],
            owner: this.formBuilder.group({
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                emailAddress: ['', [Validators.required, Validators.pattern(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i)]],
                allowEmailCommunication: ['', Validators.required],
                onboardingValue: [null, [onboardingValueConditionallyRequiredValidator]],
            }),
            chkTerms: [false, Validators.requiredTrue],
            chkPrivacyPolicy: [false, Validators.requiredTrue],
            chkAgreeAge: [false, Validators.requiredTrue]
        });

        this.loginForm = this.formBuilder.group({
            userName: ['', Validators.required],
            password: ['', [Validators.required]]
        });

        this.verifyAccountForm = this.formBuilder.group({
            verifyPassword: ['', [Validators.required]]
        });

        this.registrationService.countries.subscribe(value => {
            this.countries = value;
            const countryCode = this.registrationService.model.address.countryCode;
            if (countryCode) {
                this.step1Form.get('address.countryCode')?.setValue(countryCode);
            }
        });

        if (this.selfGuidedCertification) {
            this.registrationService.onboardingReasons.subscribe(value => {
                this.onboardingReasons = value;
            });
        }

        this.step1Form.get("address.countryCode")?.valueChanges.subscribe((newValue: string) => {
            if (newValue && this.countries) {
                const rule = this.countries.filter(c => (c.countryCode === newValue)).shift();
                if (rule) {

                    const isAllowed = (this.registrationService.productCode === ProductCodes.selfGuidedCertification && rule.isSGCAllowed) ||
                        (this.registrationService.productCode === ProductCodes.elevateSubscription && rule.isElevateRegistrationAllowed) ||
                        (this.registrationService.productCode === ProductCodes.limitedAccess && rule.isLimitedAccessRegistrationAllowed);

                    if (rule.isBusinessUnauthorized) {
                        $('#countryNotAuthorizedPopUp').modal('show');
                    } else if (!isAllowed) {
                        $('#countryCannotRegisterPopUp').modal('show');
                    } else {
                        this.step1Form.controls.address.patchValue(rule, { emitEvent: false });
                        return true;
                    }
                }
            }

            if (newValue !== null) { // the value is set to 'null' when the control is reset
                this.step1Form.get("address.countryCode")?.reset();
                this.step1Form.get("address.countryName")?.reset();
            }

            return false;
        });

        this.content = this.contentService.content.account;
    }

    ngOnInit(): void {
        if (this.registrationService.accountSetupDone) {
            this.router.navigate(["account/confirmation"]);
        }

        this.registrationService.loadRegistrationData();
        this.registrationService.model.productCode = this.registrationService.productCode;
        this.step1Form.patchValue(this.registrationService.model);
        this.registrationService.saveRegistrationData(this.registrationService.model);

        $('[data-toggle="tooltip"]').tooltip({
            placement: 'auto',
            html: true,
            trigger: 'hover',
            fallbackPlacement: ["right", "left", "top", "bottom"]
        });

        // If the product code in the saved quote doesn't match the cookie, clear out the saved quote.
        // This fixes the bug where a customer can pay the SGC price for an Elevate registration.
        if (this.registrationService.model.savedQuote?.lineItems[0].productCode !== this.registrationService.productCode) {
            this.registrationService.model.productCode = this.registrationService.productCode
            this.registrationService.model.salesQuoteId = "";
            this.registrationService.model.savedQuote = null;
            this.registrationService.saveRegistrationData(this.registrationService.model);
        }
    }

    ngOnDestroy() {
        $('[data-toggle="tooltip"]').tooltip('dispose');
    }

    get countryCodeCtrl() { return this.step1Form.get('address.countryCode') as FormControl; }
    get countryNameCtrl() { return this.step1Form.get('address.countryName') as FormControl; }
    get organizationNameCtrl() { return this.step1Form.get('organizationName') as FormControl; }
    get firstNameCtrl() { return this.step1Form.get('owner.firstName') as FormControl; }
    get lastNameCtrl() { return this.step1Form.get('owner.lastName') as FormControl; }
    get emailAddressCtrl() { return this.step1Form.get('owner.emailAddress') as FormControl; }
    get onboardingValueCtrl() { return this.step1Form.get('owner.onboardingValue') as FormControl; }
    get allowEmailCommunicationCtrl() { return this.step1Form.get('owner.allowEmailCommunication') as FormControl; }
    get chkTermsCtrl() { return this.step1Form.get('chkTerms') as FormControl; }
    get chkPrivacyPolicyCtrl() { return this.step1Form.get('chkPrivacyPolicy') as FormControl; }
    get chkAgreeAgeCtrl() { return this.step1Form.get('chkAgreeAge') as FormControl; }

    showShopLoginPopup(event: Event) {
        this.loginForm.reset();
        $('#shopLoginForm').on("shown.bs.modal", () => { $('#shopLoginFormUserName').trigger('focus'); }).modal('show');
        event.stopPropagation();
    }

    closeShopLoginPopup(formData: any, formDirective: FormGroupDirective) {
        formDirective.resetForm();
        formData.reset();
        $('#shopLoginForm').modal('hide');
    }

    async validateShopLogin() {
        $('#shopLoginFormError').hide();

        if (!this.loginForm.valid) {
            if (!this.loginForm.get('userName')?.valid || !this.loginForm.get('password')?.valid)
                $('#shopLoginFormError').show().html('Enter your username and password.');
            return false;
        }

        try {
            const token: string | null = (environment.reCaptchaOn)
                ? await this.recaptchaV3Service.execute('legacy_validation').toPromise()
                : null;

            const login: IVerifyAccountOwnerProfileModel = {
                userName: this.loginForm.value.userName,
                password: this.loginForm.value.password,
                captchaResponse: token
            };

            const isValid: boolean = await this.registrationService.getLegacyWebAccountData(login).toPromise();

            if (isValid) {
                this.step1Form.patchValue(this.registrationService.model);
                this.step1Form.get('address.countryName')?.setValue(this.countries.filter(c => (c.countryCode === this.step1Form.get('address.countryCode')?.value))[0].countryName);
                $('#shopLoginForm').modal('hide');
            } else {
                $('#shopLoginFormError').show().html('The username and/or password entered cannot be found. Please try again. <a href="https://shop.themyersbriggs.com/en/UsernameRetrieval.aspx?pgRedirect=&flow=UsernameRecovery" style="color: #FFFFFF; font-family: OpenSans-Regular;">Forgot your username?</a>&nbsp;&nbsp;<a href="https://shop.themyersbriggs.com/en/pwdretrievalform.aspx?pgRedirect=&flow=PasswordRest" style="color: #FFFFFF; font-family: OpenSans-Regular;">Forgot your password?');
            }
            
        } catch (ex) {
            console.error(ex);
            $('#shopLoginFormError').show().html('An unexpected error occurred. Please try again or contact customer support.');            
        }
        
        return false;
    }

    validateWebAccount() {
        $('#openCPPAccountLoginPopup').modal('hide');
    }

    showExistingAccountPopup() {
        $('#verifyAccount').on("shown.bs.modal",
            () => setTimeout(() => { $('#verifyPassword').trigger('focus'); }, 1000)).modal('show');
    }

    redirectToExistingAccount(f: any) {
        $('#redirectToExistingAccount').hide();
        f.resetForm();
        window.location.href = `/Subscription/Access?product=${ProductCodes.limitedAccess}&email=${encodeURIComponent(this.step1Form.get('owner.emailAddress')?.value)}`;
    }

    async validateExistingAccount() {
        $('div#verifyAccount div#passwordValidation').hide();
        
        if (!this.verifyAccountForm.valid)
            return;

        try {
            const token: string | null = (environment.reCaptchaOn)
                ? await this.recaptchaV3Service.execute('elevate_validation').toPromise()
                : null;
            const password = $("#verifyPassword").val() as string;
            const credentials: IVerifyAccountOwnerProfileModel = {
                userName: this.step1Form.get('owner.emailAddress')?.value,
                password: password,
                captchaResponse: token
            };
            const data: IMemberProfileModel[] | null = await this.registrationService.getAccountOwnerProfiles(credentials).toPromise();        
            let route: string | null = 'account/security';

            if (data) {
                this.memberProfiles = data;
                route = 'account/organization';

                $('#verifyAccount').modal('hide');

                if (this.limitedAccess && data.length > 0) {
                    $('#redirectToExistingAccount').modal('show');
                    return;
                }

                if (this.registrationService.productCode === ProductCodes.selfGuidedCertification) {
                    if (data.length === 1) {
                        this.registrationService.model.owner.profile = data[0];
                        route = 'account/review';
                    } else if (data.length) {
                        // Show the profile selection modal
                        $('#selectProfileModal').modal('show');
                        return;
                    }
                }
                
                this.registrationService.model.owner.password = password;
            }

            this.saveAndNavigate([route]);
        } catch (ex : any) {
            let message = 'An unexpected error occurred. Please try again or contact customer support.';
            switch (ex.status) {
                case 401: message = 'Login failed. Please check your password and try again.'; break;
                case 403: message = 'This account has been deactivated. Please contact customer support.'; break;
            }
            console.debug(message);
            console.error(ex);
            $('div#verifyAccount div#passwordValidation').html(message);
            $('div#verifyAccount div#passwordValidation').show();
        }        
    }

    forgotPasswordRedirect(f: any) {
        $('div#verifyAccount  div#passwordValidation').hide();
        this.verifyAccountForm.reset();
        f.resetForm();
        window.location.href = environment.loginSiteBaseUrl + "/PasswordReset/PasswordReset.aspx?Redirect=" + encodeURIComponent(window.location.href) + "&userName=" + encodeURIComponent(this.step1Form.get('owner.emailAddress')?.value);
    }

    useDifferentEmail(f: any) {
        $('div#verifyAccount  div#passwordValidation').hide();
        this.verifyAccountForm.reset();
        f.resetForm();
        $('#verifyAccount').modal('hide');
        $('#emailAddress').trigger('focus');
    }

    useDifferentEmailForLimitedAccount(f: any) {
        $('div#redirectToExistingAccount  div#passwordValidation').hide();
        this.verifyAccountForm.reset();
        f.resetForm();
        $('#redirectToExistingAccount').modal('hide');
        $('#emailAddress').trigger('focus');
    }

    lockedAccountReset() {

    }

    lockedAccountNewEmail() {

    }

    showLicenseAgreement() {
        window.open(ExternalUrls.TermsOfUse,
            'Elevate License Agreement 1',
            'top=0,left=0,scrollbars=1,resizable=1,width=950,height=700');
    }

    async next(f: any) {
        try {
            if (!this.step1Form.valid)
                return;
            
            this.nextSpinner = true;
            
            const token: string | null = (environment.reCaptchaOn)
                ? await this.recaptchaV3Service.execute('registration').toPromise()
                : null;

            const accountExists: boolean = await this.registrationService
                .hasExistingAccount(this.step1Form.value.owner.emailAddress, null, token)
                .toPromise();

            if (accountExists) {
                this.showExistingAccountPopup();
            } else {
                this.saveAndNavigate(["account/security"]);
            }

        } catch (e) {
            console.error(e);
            this.alertService.error('An unexpected error occurred. Please try again or contact customer support.');
        } finally {
            this.nextSpinner = false;
        }
    }

    saveAndNavigate(commands: any[]) {
        this.registrationService.saveRegistrationData(this.step1Form.value);
        if (this.registrationService.model.owner.profile) {
            this.nextSpinner = true;
            this.registrationService.generateSalesQuote()
                .pipe(finalize(() => this.nextSpinner = false))
                .subscribe({
                    next: (quote) => {
                        const update: IRegistrationSalesQuoteModel = {
                            savedQuote: quote,
                            salesQuoteId: quote.salesQuoteId,
                            sourceCode: quote.sourceCode,
                        };
                        this.registrationService.saveRegistrationData(update);
                        this.router.navigate(commands);
                    },
                });
        } else {
            this.nextSpinner = false;
            this.router.navigate(commands);
        }
    }

    selectProfile(form: NgForm) {
        this.registrationService.model.owner.profile = (this.memberProfiles.find(t => t.tenantId === form.value['tenantId']) as IMemberProfileModel);
        this.registrationService.model.owner.password = $("#verifyPassword").val() as string;
        $('#selectProfileModal').modal('hide');
        this.saveAndNavigate(['account/review']);
    }

    /**
     * Function that toggles the checkbox of the provided control.
     */
    toggleCheckbox(control: FormControl) {
        control.setValue(!control.value);
    }
}
