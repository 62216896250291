import { ISiteContent } from '../models/site-content-model';

export const CmsBackup: ISiteContent[] = [
    {
        parent: "ElevateRegistration",
        name: "Step1",
        heading: "",
        mainContent: "<div style=\"background-color: #eeeeee; padding: 20px; font-family: 'OpenSans-Regular'; font-size: 14px; color: #666666;\">\r\n<div style=\"font-family: 'OpenSans-Regular'; font-size: 16px; color: #528c9c; padding-bottom: 2px;\">Essential Elevate<sup style=\"font-size: xx-small; top: -0.2em;\">®</sup> License</div>\r\n<div style=\"font-family: 'OpenSans-Regular'; font-size: 14px; color: #528c9c; padding-bottom: 15px;\">1-year license for just <strong>$195</strong></div>\r\n<div style=\"padding-bottom: 0px;\">Register today and start:</div>\r\n<div style=\"padding-bottom: 0px;\" class=\".add-reports-edit-product-success-ellipse\">\r\n<table border=\"0\" align=\"center\" style=\"width: 333px; height: 56px;\">\r\n<tbody>\r\n<tr>\r\n<td><img width=\"5\" height=\"5\" src=\"http://pdpdemo.blob.core.windows.net/media/1001/bullet-point-01_8x8.jpg\" alt=\"bullet point 2\"/></td>\r\n<td><span>Saving administration time</span></td>\r\n</tr>\r\n<tr>\r\n<td><img width=\"5\" height=\"5\" src=\"http://pdpdemo.blob.core.windows.net/media/1001/bullet-point-01_8x8.jpg\" alt=\"bullet point 2\"/> </td>\r\n<td><span>Keeping your clients organized</span></td>\r\n</tr>\r\n<tr>\r\n<td><img width=\"5\" height=\"5\" src=\"http://pdpdemo.blob.core.windows.net/media/1001/bullet-point-01_8x8.jpg\" alt=\"bullet point 2\"/> </td>\r\n<td><span>Developing as a practitioner</span></td>\r\n</tr>\r\n</tbody>\r\n</table>\r\n</div>\r\n<hr />\r\n<table border=\"0\">\r\n<tbody>\r\n<tr>\r\n<td><a style=\"text-decoration: none !important; border-bottom: 1px solid;\" href=\"https://www.youtube.com/watch?v=6o0ZZnYZ0Y0&amp;feature=youtu.be\" target=\"_blank\" title=\"Elevate Explainer Video\" class=\"std-primary-link\">See how Elevate<sup>®</sup> can make your job easier.</a></td>\r\n</tr>\r\n</tbody>\r\n</table>\r\n</div>",
        companyCode: ""
    },
    {
        parent: "ElevateRegistration",
        name: "Step2",
        heading: "",
        mainContent: "<div style=\"background-color: #eeeeee; font-family: 'OpenSans-Regular'; font-size: 14px; color: #666666; padding: 20px;\">\r\n<div style=\"font-family: 'OpenSans-Regular'; font-size: 16px; color: #528c9c; padding-bottom: 15px;\">Exceptional privacy and security</div>\r\n<div style=\"padding-bottom: 15px; line-height: normal;\">\r\n<div style=\"font-family: 'OpenSans-Semibold'; font-size: 13px; color: #174f61; text-transform: uppercase;\">TRUSTED BY PRACTITIONERS</div>\r\nThe Elevate platform is trusted by practitioners just like you who are concerned not only about their data but also their respondents' data.</div>\r\n<div style=\"padding-bottom: 15px; line-height: normal;\">\r\n<div style=\"font-family: 'OpenSans-Semibold'; font-size: 13px; color: #174f61; text-transform: uppercase;\">VERIFIED AND MONITORED</div>\r\nThe Elevate platform is tested by third parties to ensure that we are protecting the security of all your personal information.</div>\r\n</div>",
        companyCode: ""
    },
    {
        parent: "ElevateRegistration",
        name: "Step3",
        heading: "",
        mainContent: "<div style=\"background-color: #eeeeee; font-family: 'OpenSans-Regular'; font-size: 14px; color: #666666; padding: 20px;\">\r\n<div style=\"font-family: 'OpenSans-Regular'; font-size: 16px; color: #528c9c; padding-bottom: 25px;\">Expert advice, personalized</div>\r\n<div style=\"padding-bottom: 15px; line-height: normal;\">CPP, Inc. is invested in providing you with results. By telling us a little bit about yourself, we can provide you with expert advice that is personalized to your needs.</div>\r\n<div style=\"padding-bottom: 15px; line-height: normal;\">\r\n<div style=\"font-family: 'OpenSans-Semibold'; font-size: 13px; color: #174f61; text-transform: uppercase;\">Get tailored solutions</div>\r\n<div>CPP, Inc. has provided solutions to millions of individuals in more than 100 countries, ranging from Fortune 500 companies to training and development consultants in varying industries.</div>\r\n</div>\r\n<div style=\"padding-bottom: 15px; line-height: normal;\">\r\n<div style=\"font-family: 'OpenSans-Semibold'; font-size: 13px; color: #174f61; text-transform: uppercase;\">Access to free online resources</div>\r\n<div>When you register on Elevate, you have unlimited access to free online resources - tips, advice, and tools.</div>\r\n</div>\r\n<div style=\"padding-bottom: 15px; line-height: normal;\">\r\n<div style=\"font-family: 'OpenSans-Semibold'; font-size: 13px; color: #174f61; text-transform: uppercase;\">Know that your data is safe</div>\r\n<div>Your data is just that, your data. CPP, Inc. is invested in protecting you and your respondent's data. We do not share, sell, or rent your information to third parties.</div>\r\n</div>\r\n</div>",
        companyCode: ""
    },
    {
        parent: "ElevateRegistration",
        name: "Step4",
        heading: "",
        mainContent: "<div style=\"background-color: #eeeeee; font-family: 'OpenSans-Regular'; font-size: 14px; color: #666666; padding: 20px;\">\r\n<div style=\"font-family: 'OpenSans-Regular'; font-size: 16px; color: #528c9c; padding-bottom: 15px;\">What our customers are saying</div>\r\n<div style=\"padding-bottom: 15px;\">\r\n<div>\r\n<div style=\"margin-bottom: 5px;\"><strong>\"I'm thrilled with the assessment completion notifications.</strong> Thanks for listening to us!\"</div>\r\nJane Hoffman, Delaware Valley, APTi</div>\r\n</div>\r\n<div style=\"padding-bottom: 15px;\">\r\n<div>\r\n<div style=\"margin-bottom: 5px;\"><strong>\"Elevate will save me time</strong>...my scarcest resource!\"</div>\r\nCurt Lawhead, Philips</div>\r\n</div>\r\n<div style=\"padding-bottom: 15px;\">\r\n<div>\r\n<div style=\"margin-bottom: 5px;\"><strong>\"I am so grateful to CPP for creating the Elevate platform, which will make my job so much easier. </strong>Elevate is so easy to use and convenient. We love CPP!\"</div>\r\nNicole Keenan, Director of Student Success &amp; Career Development, University of Kentucky</div>\r\n</div>\r\n<div style=\"padding-bottom: 15px;\">\r\n<div>\r\n<div style=\"margin-bottom: 5px;\">\"Elevate is <strong>very intuitive!\"</strong></div>\r\nNav Dutta, Hult International School of Business</div>\r\n</div>\r\n</div>",
        companyCode: ""
    },
    {
        parent: "ElevateRegistration",
        name: "Confirmation",
        heading: "",
        mainContent: "<div>\n<div class=\"section-subheader-redesign my-4\">You have successfully registered for Elevate.</div>\n<div class=\"content\" style=\"background-color: white;\">Thank you for registering on the Elevate platform. You will be emailed a receipt with your purchase details once your order has been processed. <br /><br /> Click the<strong> Continue to Log In </strong>button to log in to your Elevate account and begin sending assessments, purchasing inventory, and generating reports. <br /><br /> <em>Note: </em>Don't forget to bookmark elevate.themyersbriggs.com. This is where you will access your account. <br /><br /> If you have any questions, our Customer Support team is available to help you, please <a href='https://login.themyersbriggs.com/support/contact.aspx'>contact us</a> for assistance.</div>\n</div>",
        companyCode: ""
    },
    {
        parent: "SelfGuidedCertification",
        name: "Confirmation",
        heading: "",
        mainContent: "<div>\n<div class=\"section-subheader-redesign my-4\">You're registered for MBTI<sup class=\"reg sub-header\">®</sup> certification!</div>\n<div class=\"content\" style=\"background-color: white;\">Thank you for purchasing the Self-Guided MBTI<sup class=\"reg\">®</sup> Certification Program. You'll receive a receipt by email as soon as we've processed your order. <br /><br /> Click the \"Continue to log in\" button to get started with your certification. You'll have access to the program for 90 days from today. <br /><br /> Don't forget to bookmark elevate.themyersbriggs.com so that you can easily come back and access your online learning. <br /> Any questions? Our Customer Support team is available to help you, please <a href='https://login.themyersbriggs.com/support/contact.aspx'>contact us</a> for assistance.</div>\n</div>",
        companyCode: ""
    },
    {
        parent: "LimitedAccess",
        name: "Confirmation",
        heading: "Congratulations",
        mainContent: "<div>\n<div class=\"section-subheader-redesign my-4\">Congratulations</div>\n<div class=\"content\" style=\"background-color: white;\">You have created your account on the Elevate® assessment website. A confirmation email has been sent to you.<br /><br /> Continue to log in below and complete your purchase.</div>\n</div>",
        companyCode: ""
    }
];
