export interface IAddressModel {
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    city: string;
    stateOrRegion: string;
    postalCode: string;
    countryCode: string;
    countryName: string;
    validated: boolean;
}
export class AddressModel implements IAddressModel {
    addressLine1 = '';
    addressLine2?: string;
    addressLine3?: string;
    city = '';
    stateOrRegion = '';
    postalCode = '';
    countryCode = '';
    countryName = '';
    validated = false;
}
