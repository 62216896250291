import { Injectable } from '@angular/core';

import { RegistrationService } from '../services/registration.service';
import { AppService } from '../../app.service';

import { ISiteContent } from '../models/site-content-model';
import { ProductCodes } from '../models/product-codes';
import { IRegistrationContent } from '../models/registration-content.model';
import { RegistrationContent } from '../body-copy/registration-content'
import { CmsBackup } from '../body-copy/cms-backup'

@Injectable({
    providedIn: 'root'
})
export class ContentService {
    content!: IRegistrationContent;
    private _siteContent: ISiteContent[] = [];
    private _nodes = new Array(3);

    constructor(
        private registrationService: RegistrationService,
        private appService: AppService) { }

    initializeConfiguration(): Promise<boolean | void> {
        let nodes: ISiteContent[] = [];

        return this.registrationService.siteContent
            .toPromise()
            .then(value => {
                nodes = value;
            })
            .catch(err => {
                nodes = CmsBackup;
                this.appService.handleError(err);
            })
            .finally(() => {  
                this._nodes[0] = nodes.filter(n => n.parent === 'ElevateRegistration'); 
                this._nodes[1] = nodes.filter(n => n.parent === 'SelfGuidedCertification');
                this._nodes[2] = nodes.filter(n => n.parent === 'LimitedAccess');

                switch (this.registrationService.productCode) {
                    case ProductCodes.limitedAccess:
                        this._siteContent = this._nodes[2];
                        this.content = RegistrationContent[2];
                        break;
                    case ProductCodes.selfGuidedCertification:
                        this._siteContent = this._nodes[1];
                        this.content = RegistrationContent[1];
                        break;
                    default: // ProductCodes.elevateRegistration
                        this._siteContent = this._nodes[0];
                        this.content = RegistrationContent[0];
                        break;      
                }
            }) as Promise<boolean | void>;
    }

    siteContent(step: string): ISiteContent | undefined {
        return this._siteContent.find(n => n.name === step);
    }

    contentsByProduct(productCode: string): IRegistrationContent {
        switch (productCode) {
            case ProductCodes.limitedAccess:
                return RegistrationContent[2];
            case ProductCodes.selfGuidedCertification:
                return RegistrationContent[1];
            default: // ProductCodes.elevateRegistration
                return RegistrationContent[0];
        }
    }

    siteContentByProduct(productCode: string, step: string): ISiteContent | undefined {
        switch (productCode) {
            case ProductCodes.limitedAccess:
                return (this._nodes[2] as ISiteContent[]).find(n => n.name === step);  
            case ProductCodes.selfGuidedCertification:
                return (this._nodes[1] as ISiteContent[]).find(n => n.name === step);
            default: // ProductCodes.elevateRegistration
                return (this._nodes[0] as ISiteContent[]).find(n => n.name === step);  
        }
    }
}
